import React from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Card,
    CardContent,
    // Divider,
    Grid,
    Typography,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    // TableHead,
    // TablePagination,
    TableRow,
    Checkbox
} from '@material-ui/core';
import { ISOCheckBox } from 'src/framework/components/controls';

// import {
//   AccessTime as AccessTimeIcon,
//   Work as GetAppIcon,
//   Build as BuildIcon,
// } from '@material-ui/icons';

const CheckItemCard = (props) => {
    const { checkItem, onClick, onCheckOneItem } = props;

    const selectOneItem = (e) => {
        //클릭이벤트가 checkbox는 안되도록 할수있다.
        e.stopPropagation();
        onCheckOneItem(checkItem.RESULT_ID, e.target.checked);
    };
    return (
        <Card className="CardContentGridItem CardBox" onClick={onClick}>
            <CardContent>
                <div className="CardBox_tit">
                    <div className="tit">
                        {checkItem.TITLE}
                        {checkItem.SHIFT_NM ? <span className="tit_item"> {checkItem.SHIFT_NM}</span> : ''}
                        <span className="tit_sub">{checkItem.FACILITY_NAME}</span>
                    </div>

                    <div className="check_box">
                        {checkItem.STATUS !== 'M_CP_STATUS_APPROVE' ? (
                            <Checkbox checked={checkItem.CHK ? true : false} onClick={selectOneItem} />
                        ) : (
                            ''
                        )}
                    </div>
                </div>
                <div className="CardBox_con">
                    <div className="tit_time">
                        {checkItem.START_DATE} ~ {checkItem.END_DATE}
                    </div>

                    <div className="check_end">{checkItem.STATUS_NM}</div>
                    {/* <div>{checkItem.needApproval}</div> */}
                </div>
            </CardContent>
        </Card>
    );
};

export default CheckItemCard;
