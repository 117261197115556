import crypto from 'crypto';
import base64 from 'base-64';
import * as CryptoJS from 'crypto-js';

export default function useBase64() {
    const cfg = {
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    };
    const saltKey = 'reactISOe3Framework';

    const encryption = (text) => crypto.pbkdf2Sync(text, saltKey, 1, 64, 'sha512').toString('base64');
    const createHash = (text) => crypto.createHash('sha1').update(text).digest('hex');
    const createPublicKey = () => crypto.createPublicKey(createPublicKey);
    const encryptAES = (text) => CryptoJS.AES.encrypt(text, saltKey).toString();
    const decryptAES = (encryptedBase64) => {
        const decrypted = CryptoJS.AES.decrypt(encryptedBase64, saltKey);
        if (decrypted) {
            try {
                const str = decrypted.toString(CryptoJS.enc.Utf8);
                if (str.length > 0) {
                    return str;
                }
                return 'error 1';
            } catch (e) {
                return 'error 2';
            }
        }
        return 'error 3';
    };

    return {
        createPublicKey,
        encryption,
        createHash,
        encryptAES,
        decryptAES
    };
}
