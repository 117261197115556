import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Card,
    CardContent,
    // Divider,
    Grid,
    Typography,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    // TableHead,
    // TablePagination,
    TableRow,
    RadioGroup,
    Radio,
    FormControlLabel
} from '@material-ui/core';
import { ISOCheckBox, ISOInput, ISOTextField, ISORadio } from 'src/framework/components/controls';
import ISOAxios, { DataPackage } from 'src/framework/core/apis/ISOAxios';
import { ViewArraySharp } from '@material-ui/icons';
// import {
//   AccessTime as AccessTimeIcon,
//   Work as GetAppIcon,
//   Build as BuildIcon,
// } from '@material-ui/icons';

const SheetItemRadio = (props) => {
    const { item, updateSheetItems, status } = props;
    const handleChange = (event, value) => {
        updateSheetItems(item.PLAN_MASTER_ID, item.INDEX_MASTER_ID, value, '', '', item.DESC_IDX);
    };
    const resLenChk = (item) => {
        let array = [];

        let descArr = item.ITEM_DESC;
        for (let i = 0; i < descArr.length; i++) {
            array.push(
                <FormControlLabel
                    key={i}
                    value={descArr[i].VALUE}
                    control={<Radio />}
                    label={descArr[i].LABEL}
                    disabled={
                        status === 'M_CP_STATUS_REQUEST' ||
                        status === 'M_CP_STATUS_COMPLETE' ||
                        status === 'M_CP_STATUS_APPROVE'
                            ? true
                            : false
                    }
                />
            );
        }

        return array;
    };
    return (
        <div className="check_sheet_con con_line">
            <div className={item.DEFAULT_YN === 'Y' ? 'check_sheet_problem bad' : 'check_sheet_problem'}>
                {item.MCP_GBN.replace('!$!', '')}
            </div>
            <div className="check_sheet_ex">
                <RadioGroup
                    aria-label={item.INDEX_MASTER_ID}
                    name="row-radio-buttons-group"
                    value={item.ITEMS_VALUE ? item.ITEMS_VALUE[0].ITEMS_VALUE : ''}
                    onChange={handleChange}
                >
                    {/* {item?.ITEMS_DESC?.map((desc, idx) => {
                        return <FormControlLabel key={idx} value={desc.VALUE} control={<Radio />} label={desc.LABEL} />;
                    })} */}
                    {resLenChk(item)}
                </RadioGroup>
            </div>
        </div>
    );
};

export default SheetItemRadio;
