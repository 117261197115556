import React, { useEffect, useState } from 'react';
import { createBrowserHistory } from 'history';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
    Box,
    Button,
    Container,
    Grid,
    Card,
    CardContent,
    Typography,
    ButtonGroup,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import ISONotification from 'src/framework/components/controls/ISONotification';
import ISOAxios, { DataPackage } from 'src/framework/core/apis/ISOAxios';
import Loader from 'src/components/loading/Loader';
import { e3, alertType } from 'src/framework/core/utils/utils';
import { ISOCheckBox, ISOCombobox, ISODateTime } from 'src/framework/components/controls';
import CheckItemCard from 'src/components/CheckList/CheckItemCard';
import CheckInput_PopUp from './popup/CheckInput_PopUp';
import useSessionStorage from 'src/framework/core/hooks/useSessionStorage';
import SelectForeman from './popup/SelectForeman';

const CheckList = () => {
    const [isLoading, setIsLoading] = React.useState(true); // 파일 저장 및 삭제 동안 이동을 막기 위함
    const [checkItems, setCheckItems] = useState([]);
    const [remainItems, setRemainItems] = useState([]); //잔여리스트 데이터
    const [remainCnt, setRemainCnt] = React.useState(0);
    const [remainYn, setRemainYn] = useState(false); //잔여리스트 화면 출력 여부
    const [renderCnt, setRenderCnt] = useState(false);
    const [oriItems, setOriItems] = useState([]); //조회조건에 맞는 데이터(처음 검색해온 데이터)
    const checkInput_dialog = React.useRef(CheckInput_PopUp);
    const dateGrid = React.useRef(Grid);
    const stateGrid = React.useRef(Grid);
    const remainGrid = React.useRef(Grid);
    const refSelectForeman = React.useRef(SelectForeman);

    //검색조건
    const searchDate = React.useRef(ISODateTime);
    const searchStatus = React.useRef(ISOCombobox);

    //정렬
    const sortCombo = React.useRef(ISOCombobox);

    const { loadSessionStorage } = useSessionStorage('loginSign');
    const loginUser = loadSessionStorage();
    console.log('loginuser:::', loginUser);

    //PLANT_ID 하드코딩
    loginUser.PLANT_ID = loginUser.PLANT_PRCS_ID;
    // Notify 출력 설정
    const [notify, setNotify] = React.useState({ isOpen: false, message: '', type: '' });

    //전체선택/해제
    const [chkAll, setChkAll] = useState(false);

    const history = createBrowserHistory();
    const navigate = useNavigate();
    useEffect(async () => {
        setIsLoading(false);
        remainGrid.current.hidden = true;
        await searchSortCombo();
        searchStatusCombo();
    }, []);

    window.onpopstate = function (event) {
        checkInput_dialog.current?.closeDialog();
        navigate('/app/checklist');
    };

    useEffect(async () => {
        if (renderCnt) {
            remainSearch();
        }
        setRenderCnt(true);
    }, [remainYn]);

    const openCheckInputPopup = (param) => {
        checkInput_dialog.current.showDialog(param);
    };

    const onCheckOneItem = (resultId, checked) => {
        const changedList = checkItems.map((item) =>
            item.RESULT_ID === resultId && item.STATUS === 'M_CP_STATUS_SAVE'
                ? {
                      ...item,
                      CHK: checked
                  }
                : item
        );
        setCheckItems(changedList);
        setChkAll(false);
    };
    const selectAllCheck = async (e) => {
        setChkAll(e.target.checked);
        onChangeCheckAll(e.target.checked);
    };

    const onChangeCheckAll = (checked) => {
        let changedList = [];
        if (checked === true) {
            changedList = checkItems.map((item) =>
                //상태가 '저장' 항목만 선택되도록
                item.STATUS === 'M_CP_STATUS_SAVE' ? { ...item, CHK: true } : { ...item }
            );
        } else {
            changedList = checkItems.map((item) => ({
                ...item,
                CHK: false
            }));
        }

        setCheckItems(changedList);
    };

    //상태 콤보 박스 데이터 출력
    const searchStatusCombo = async () => {
        let param = DataPackage.create('CHK365', 'searchStatusCombo');
        param.KeyValues.push(DataPackage.NewKeyValue('PLANT_ID', loginUser.PLANT_ID));
        try {
            let response = (await ISOAxios.Execute(param)).JsonData;
            searchStatus.current.setDataBinding(response);
        } catch (error) {
            e3.modal.alert(alertType.Error, error.message);
        }
        //await onRemainSearch();
        await onSearch();
    };
    //정렬 콤보 박스 데이터 출력
    const searchSortCombo = async () => {
        let param = DataPackage.create('CHK365', 'searchSortCombo');
        try {
            let response = (await ISOAxios.Execute(param)).JsonData;
            sortCombo.current.setDataBinding(response);
        } catch (error) {
            e3.modal.alert(alertType.Error, error.message);
        }
    };

    //잔여리스트 데이터
    const onRemainSearch = async () => {
        let param = DataPackage.create('CHK365', 'SelectRePlanList');
        try {
            let response = (await ISOAxios.Execute(param)).JsonData;
            response = JSON.parse(response);
            setRemainItems(response);
            //잔여리스트 개수 출력
            let newRes = response[0] ? response[0].CNT : 0;
            setRemainCnt(response.length);
        } catch (error) {
            e3.modal.alert(alertType.Error, error.message);
        }
    };

    //잔여리스트 버튼 클릭
    const remainSearchClick = () => {
        if (remainCnt === 0) {
            e3.modal.alert(alertType.Error, '전월 미점검 리스트가 없습니다.');
            return;
        }
        remainYn === false ? setRemainYn(true) : setRemainYn(false);
    };

    //잔여리스트 출력
    const remainSearch = async () => {
        setChkAll(false);
        remainYn === true
            ? ((dateGrid.current.hidden = true),
              (stateGrid.current.hidden = true),
              (remainGrid.current.hidden = false),
              setCheckItems(remainItems))
            : ((dateGrid.current.hidden = false),
              (stateGrid.current.hidden = false),
              (remainGrid.current.hidden = true),
              setCheckItems(oriItems));
    };

    //점검 계획 목록 출력
    const onSearch = async () => {
        setIsLoading(true);
        setRemainYn(false);
        dateGrid.current.hidden = false;
        stateGrid.current.hidden = false;
        let param = DataPackage.create('CHK365', 'SelectPlanList');
        param.KeyValues.push(DataPackage.NewKeyValue('SEARCH_DATE', searchDate.current.getDate2String('yyyy-MM')));
        param.KeyValues.push(DataPackage.NewKeyValue('STATUS', searchStatus.current.getValue()));

        try {
            let response = (await ISOAxios.Execute(param)).JsonData;
            response = JSON.parse(response);
            console.log('response:::', response);
            setCheckItems(response);
            setOriItems(response);
            //sortChange('NAME_SORT_1');
        } catch (error) {
            e3.modal.alert(alertType.Error, error.message);
        }
        //잔여리스트
        onRemainSearch();
        setIsLoading(false);
    };

    const sortChange = (value) => {
        //점검명 오름차순
        if (value === 'NAME_SORT_1') {
            handleSort(true, 'TITLE');
            return;
        }
        //점검명 내림차순
        if (value === 'NAME_SORT_2') {
            handleSort(false, 'TITLE');
            return;
        }
        //마감날짜 오름차순
        if (value === 'END_SORT_1') {
            handleSort(true, 'END_DATE');
            return;
        }
        //마감날짜 내림차순
        if (value === 'END_SORT_2') {
            handleSort(false, 'END_DATE');
            return;
        }
    };

    const handleSort = (asc, column) => {
        if (checkItems.length > 0) {
            const sortedList = [...checkItems].sort((a, b) => {
                let x = a[column].toLowerCase();
                let y = b[column].toLowerCase();

                if (x < y) {
                    return asc ? -1 : 1;
                } else if (x > y) {
                    return asc ? 1 : -1;
                }
                return 0;
            });

            setCheckItems(sortedList);
        }
    };

    //점검완료(결재상신) 클릭
    const onAllApproveClick = () => {
        //e3.modal.alert(alertType.Error, '현재 모바일에서 결재상신 기능을 지원하지 않습니다. EHS에서 진행해주세요');
        const approveList = checkItems.filter((item) => item.CHK === true && item.APPROVAL_YN === 'N');
        const requestApproveList = checkItems.filter(
            (item) => item.CHK === true && item.APPROVAL_YN !== 'N' && item.APPROVAL_LINE_ID
        );

        const validationValue = approveList.length > 0 || requestApproveList.length > 0;
        if (validationValue) {
            let shouldSelectForeman = false;
            let foremanList = [];
            for (let i = 0; i < requestApproveList.length; i++) {
                if (requestApproveList[i].FOREMAN_LIST) {
                    shouldSelectForeman = true;
                    foremanList = JSON.parse(requestApproveList[i].FOREMAN_LIST);
                    break;
                }
            }

            e3.modal.confirm('확인', '선택된 항목들을 점검완료(결재상신) 하시겠습니까? ', () =>
                selectForeman(approveList, requestApproveList, shouldSelectForeman, foremanList)
            );
        } else {
            e3.modal.alert(alertType.Error, '선택된 항목이 없습니다');
            return;
        }
    };

    // FOREMAN 선택 팝업 호출
    const selectForeman = (approveList, requestApproveList, shouldSelectForeman, foremanList) => {
        if (shouldSelectForeman) {
            refSelectForeman.current.showDialog({ approveList, requestApproveList }, foremanList);
        } else {
            onAllApprove({ approveList, requestApproveList }, '');
        }
    };

    //결재상신
    const onAllApprove = async ({ approveList, requestApproveList }, FOREMAN_EMP_NO) => {
        setIsLoading(true);
        try {
            // 점검완료 처리
            let param = DataPackage.create('CHK365', 'SaveAllApprove');
            param.JsonData = JSON.stringify(
                approveList.map((e) => ({
                    PLANT_PRCS_ID: e.PLANT_PRCS_ID,
                    PLAN_MASTER_ID: e.PLAN_MASTER_ID,
                    RESULT_ID: e.RESULT_ID,
                    PLAN_TYPE: e.PLAN_TYPE,
                    FACILITY_ID: e.FACILITY_ID
                }))
            );
            await ISOAxios.Execute(param);

            param = DataPackage.create('CHK365', 'SaveAllRequest');
            param.JsonData = JSON.stringify(
                requestApproveList.map((e) => ({
                    PLANT_PRCS_ID: e.PLANT_PRCS_ID,
                    PLAN_MASTER_ID: e.PLAN_MASTER_ID,
                    RESULT_ID: e.RESULT_ID,
                    APPROVAL_LINE_ID: e.APPROVAL_LINE_ID,
                    FOREMAN_EMP_NO: FOREMAN_EMP_NO,
                    PLAN_TYPE: e.PLAN_TYPE,
                    FACILITY_ID: e.FACILITY_ID
                }))
            );
            await ISOAxios.Execute(param);
        } catch (error) {
            e3.modal.alert(alertType.Error, error.message);
        }
        setIsLoading(false);
        await onSearch();
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>모바일365캘린더</title>
            </Helmet>

            <Box className="DashboardLayoutContentBox">
                <Container maxWidth={false}>
                    <Box>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} lg={12} xl={12} className="pr-10 mb-10">
                                <div className="user_area">
                                    <div className="user_area_part">
                                        {`${decodeURI(loginUser.DEPART_NAME)}`} {`${decodeURI(loginUser.USER_NAME)}`}
                                    </div>
                                    <div className="number_list">
                                        {remainYn === true ? (
                                            <a onClick={remainSearchClick}>점검목록으로</a>
                                        ) : (
                                            <a onClick={remainSearchClick}>
                                                전월 미점검 List <span>{remainCnt}개</span>
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </Grid>

                            <Grid ref={dateGrid} item xs={6} sm={6} lg={6} xl={6} className="pr-10 mb-10">
                                <div className="MuiInputAdornment-root">
                                    <span>점검기간</span>
                                </div>
                                <ISODateTime
                                    ref={searchDate}
                                    dateFormat={'yyyy-MM'}
                                    showMonthYearPicker={true}
                                    required={true}
                                    onChange={(e) => {
                                        setTimeout(onSearch, 500);
                                    }}
                                />
                            </Grid>
                            <Grid ref={stateGrid} item xs={6} sm={6} lg={6} xl={6} className="mb-10">
                                <div className="MuiInputAdornment-root">
                                    <span>상태</span>
                                </div>
                                <ISOCombobox
                                    ref={searchStatus}
                                    defaultIndices={[0, 1]}
                                    multiable={true}
                                    required={true}
                                    onChange={(e) => {
                                        setTimeout(onSearch, 500);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} lg={6} xl={6} className="pr-5 mt-10">
                                <FormControlLabel
                                    checked={chkAll}
                                    value=""
                                    control={<Checkbox />}
                                    label={'전체선택/해제'}
                                    onChange={selectAllCheck}
                                />
                            </Grid>
                            <Grid item xs={6} sm={6} lg={6} xl={6}>
                                <div className="sort-select">
                                    <ISOCombobox
                                        ref={sortCombo}
                                        defaultIndex={0}
                                        onChange={(e) => {
                                            sortChange(e.value);
                                        }}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box className="mt-10">
                        <div hidden={remainYn ? false : true} className="MuiInputAdornment-root mb-10">
                            전월 미점검 목록
                            {/* <span class="fc_gray">(최근 2주 내)</span> */}
                        </div>
                        <Grid container spacing={1}>
                            {checkItems && checkItems.length > 0 ? (
                                checkItems.map((checkItem, index) => (
                                    <Grid item key={index} xs={12} sm={6} lg={6} xl={6} className="card-list">
                                        <CheckItemCard
                                            checkItem={checkItem}
                                            onClick={() => openCheckInputPopup(checkItem)}
                                            onCheckOneItem={onCheckOneItem}
                                        />
                                    </Grid>
                                ))
                            ) : (
                                <Grid item xs={12} sm={12} lg={12} xl={12} className="card-list empty">
                                    <Card className="CardContentGridItem">
                                        <CardContent>
                                            <em></em>
                                            <Typography color="textPrimary" gutterBottom variant="h3">
                                                조회된 자료가 존재하지 않습니다.
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                    {/* <Box className="btm_button_payment">결재상신</Box> */}
                    <div className="full-button ml--15 mr--15">
                        <ButtonGroup disableElevation fullWidth variant="contained">
                            <Button color="primary" className="major-button" onClick={onAllApproveClick}>
                                점검완료(결재상신)
                            </Button>
                        </ButtonGroup>
                    </div>
                </Container>
            </Box>
            <CheckInput_PopUp ref={checkInput_dialog} onSearch={onSearch} />
            <SelectForeman ref={refSelectForeman} callbackFn={onAllApprove} />
            <ISONotification notify={notify} setNotify={setNotify} />
            <Loader isShow={isLoading} />
        </React.Fragment>
    );
};
export default CheckList;
