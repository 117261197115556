import { createTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const theme = createTheme({
    palette: {
        background: {
            default: '#fff',
            paper: colors.common.white
        },
        primary: {
            contrastText: '#333',
            main: '#e74536'
        },
        text: {
            primary: '#e74536',
            secondary: '#0058b2'
        }
    },
    shadows,
    typography
});

export default theme;
