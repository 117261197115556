import React, { useEffect, useRef, useState } from 'react';
import ISOAxios, { DataPackage } from 'src/framework/core/apis/ISOAxios';
import { alertType, e3, validationType } from 'src/framework/core/utils/utils';
import {
    Box,
    Button,
    Container,
    Grid,
    Typography,
    TextField,
    Select,
    Checkbox,
    Card,
    CardHeader,
    CardContent,
    ButtonGroup,
    TextArea
} from '@material-ui/core';
import {
    ISODialog,
    ISODialogTitle,
    ISODialogContent,
    ISODialogActions
} from '../../framework/components/controls/ISODialog';
import moment from 'moment';
import ActionEvent from 'src/components/actionevent/ActionEvent';
import FileUpload from 'src/components/files/FileUpload';
import { ISOCheckBox, ISOCombobox, ISODateTime, ISOInput, ISOTextField } from 'src/framework/components/controls';
import SheetForm from '../SheetForm';
import { RepeatRounded } from '@material-ui/icons';
import ISOTextArea from 'src/framework/components/controls/ISOTextArea';
import FilesContainer from './FilesContainer';
import Loader from 'src/components/loading/Loader';
import { RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import SelectForeman from './SelectForeman';

/**
 * 점검결과입력 - 팝업
 * @returns {JSX.Element}
 * @constructor
 */

const CheckInput_PopUp = React.forwardRef((props, ref) => {
    const { onSearch } = props;
    const [isOpen, setIsOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);
    const summary = React.useRef(ISOTextArea);
    const refSelectForeman = React.useRef(SelectForeman);

    const sheetFormRef = React.useRef([]);
    const refFuYn = React.useRef(ISOCombobox);
    const refFilesContainer = React.useRef(FilesContainer);

    const [item, setItem] = useState({});
    const [sheetItems, setSheetItems] = useState([]);
    const [summaryState, setSummaryState] = useState(false);
    const [refFuYnState, setRefFuYnState] = useState(false);
    const [factList, setFactList] = useState([]); //체크리스트 경우 설비

    /* 10. function */
    // 팝업 오픈 및 조회
    React.useImperativeHandle(ref, () => ({
        showDialog(param) {
            setItem(param);
            setIsOpen(true);
            // setTimeout(() => {}, 600);
            setFactList(JSON.parse(param.FACILITY_ID));
        },
        getValidation(item) {
            let result = approveValidation(item);
            return result;
        },
        closeDialog() {
            if (isOpen === true) {
                onClose();
            }
        }
    }));
    useEffect(() => {
        setIsLoading(false);
        if (summaryState && item) {
            summary.current.setValue(item.SUMMARY ? item.SUMMARY : '');
        }
    }, [summaryState, item]);

    useEffect(() => {
        if (refFuYnState && item) {
            refFuYn.current.setValue(item.FU_YN ? item.FU_YN : '');
            //f/u필요 결재상신 상태일 경우 비활성화
            item.STATUS === 'M_CP_STATUS_REQUEST' ||
            item.STATUS === 'M_CP_STATUS_COMPLETE' ||
            item.STATUS === 'M_CP_STATUS_APPROVE'
                ? refFuYn.current.disable()
                : refFuYn.current.enable();
        }
    }, [refFuYnState, item]);
    // 팝업 종료
    const onClose = async () => {
        while (sheetFormRef.current.length > 0) {
            sheetFormRef.current.pop();
        }

        setItem({});
        setSummaryState(false);
        setRefFuYnState(false);
        props.onSearch();
        setIsOpen(false);
    };

    const onSaveClick = async () => {
        e3.modal.confirm('확인', '저장하시겠습니까?', onSave);
    };

    const onValidation = (res) => {
        let returnValue = true;
        //체크리스트 점검항목의 경우 DEFAULT 값 지정되어있어서 입력이 안된 항목은 없을 것
        for (let i = 0; i < res.length; i++) {
            if (res[i].OBJECT_TYPE === 'checkbox') {
                let checkCnt = 0;
                for (let y = 0; y < res[i].ITEM_DESC.length; y++) {
                    if (res[i].ITEM_DESC[y].CHECKED === '') {
                        checkCnt += 1;
                    } else {
                        continue;
                    }
                }
                if (checkCnt === res[i].ITEM_DESC.length) {
                    e3.modal.alert(alertType.Error, '답변하지 않은 항목이 있습니다!');
                    returnValue = false;
                    return;
                }
                continue;
            }
            if (
                (res[i].OBJECT_TYPE !== 'checkbox' && res[i].ITEMS_VALUE === null) ||
                res[i].ITEMS_VALUE[0].ITEMS_VALUE === null ||
                res[i].ITEMS_VALUE[0]?.ITEMS_VALUE === ''
            ) {
                e3.modal.alert(alertType.Error, '답변하지 않은 항목이 있습니다!');
                returnValue = false;
                return;
            }
            let summaryValue = summary.current.getValue().trim();
            if (res[i].DEFAULT_YN && res[i].DEFAULT_YN === 'Y' && summaryValue.length === 0) {
                e3.modal.alert(alertType.Error, '점검내용을 입력해주세요!');
                returnValue = false;
                return;
            }
            continue;
        }
        return returnValue ? true : false;
    };

    const onSave = async () => {
        //답변안한 점검항목 있는지 확인 후 저장
        let validationValue = true;
        sheetFormRef.current.forEach(function (ele, index) {
            let res = ele?.getResult();
            if (!res) return;
            if (validationValue === true) {
                onValidation(res) ? (validationValue = true) : (validationValue = false);
            }
        });

        //F/U필요 선택여부 확인
        if (refFuYn.current.getValue() === '' || refFuYn.current.getValue() === null) {
            e3.modal.alert(alertType.Error, 'F/U 필요여부는 필수 선택값 입니다!');
            return;
        }
        //상태가 '계획','저장','결재반려' 외에는 저장불가
        if (
            item.STATUS === 'M_CP_STATUS_REQUEST' ||
            item.STATUS === 'M_CP_STATUS_COMPLETE' ||
            item.STATUS === 'M_CP_STATUS_APPROVE'
        ) {
            e3.modal.alert(alertType.Error, '이미 결재가 된 건은 저장하실 수 없습니다.');
            return;
        }

        //점검항목 validation 에서 true로 넘어오면 저장진행
        if (validationValue === true) {
            setIsLoading(true);
            sheetFormRef.current.forEach(function (ele) {
                let result = ele?.getResult();
                if (!result) return;
                let param = DataPackage.create('CHK365', 'SaveMcptResult');
                param.KeyValues.push(DataPackage.NewKeyValue('PLANT_PRCS_ID', result[0].PLANT_PRCS_ID));
                param.KeyValues.push(DataPackage.NewKeyValue('PLAN_MASTER_ID', result[0].PLAN_MASTER_ID));
                param.KeyValues.push(DataPackage.NewKeyValue('RESULT_ID', result[0].RESULT_ID));
                param.KeyValues.push(DataPackage.NewKeyValue('SUMMARY', summary.current.getValue() || ''));
                param.KeyValues.push(DataPackage.NewKeyValue('FU_YN', refFuYn.current.getValue() || ''));
                // param.KeyValues.push(DataPackage.NewKeyValue('APPROVAL_YN', item.APPROVAL_YN || 'Y'));
                param.JsonData = JSON.stringify(
                    result.map((e) => ({
                        PLANT_PRCS_ID: e.PLANT_PRCS_ID,
                        INDEX_MASTER_ID: e.INDEX_MASTER_ID,
                        RESULT_ID: e.RESULT_ID,
                        ITEMS_VALUE: e.ITEMS_VALUE ? e.ITEMS_VALUE[0].ITEMS_VALUE : '',
                        PLAN_MASTER_ID: e.PLAN_MASTER_ID,
                        ITEM_DESC: JSON.stringify(e.ITEM_DESC) || '',
                        FACILITY_ID: e.FACILITY_ID ? e.FACILITY_ID : '',
                        OBJECT_TYPE: e.OBJECT_TYPE,
                        PLAN_TYPE: item.PLAN_TYPE,
                        ITEM_NAME: e.ITEM_NAME
                    }))
                );
                try {
                    ISOAxios.Execute(param);
                } catch (error) {
                    e3.modal.alert(alertType.Error, error.message);
                }
            });

            let saveFileRes = true;
            saveFileRes = await refFilesContainer.current?.saveFiles();
            if (saveFileRes) {
                e3.modal.alert(alertType.Success, '저장 되었습니다.');
                onClose();
            }
            setIsLoading(false);
        }
    };

    //점검내용 ready 되면 state 변경
    const onReady = () => {
        setSummaryState(true);
        //setRefFuYnState(true);
    };
    //f/u필요 ready 되면 state 변경
    const onFuYnReady = () => {
        setRefFuYnState(true);
    };

    const approveValidation = (item) => {
        if (item.STATUS === 'M_CP_STATUS_PLAN' && item.APPROVAL_YN === 'N') {
            e3.modal.alert(alertType.Error, '저장 후 점검완료 가능합니다.');
            return false;
        }
        if (item.STATUS === 'M_CP_STATUS_PLAN' && item.APPROVAL_YN !== 'N') {
            e3.modal.alert(alertType.Error, '저장 후 결재상신 가능합니다.');
            return false;
        }
        if (item.STATUS === 'M_CP_STATUS_REQUEST') {
            e3.modal.alert(alertType.Error, '결재상신 된 건이 있습니다.');
            return false;
        }
        if (item.STATUS === 'M_CP_STATUS_APPROVE') {
            e3.modal.alert(alertType.Error, '결재가 완료 된 건은 상신할 수 없습니다.');
            return false;
        }
        if (item.FU_YN === '') {
            e3.modal.alert(alertType.Error, 'F/U 필요 여부는 필수 선택 값 입니다.');
            return false;
        } else {
            return true;
        }
    };

    const onApproveClick = () => {
        if (approveValidation(item)) {
            item.APPROVAL_YN !== 'N'
                ? e3.modal.confirm('확인', '결재상신 하시겠습니까?', onRequest)
                : //e3.modal.alert(alertType.Error, '현재 모바일에서 결재상신 기능을 지원하지 않습니다. EHS에서 진행해주세요')
                  e3.modal.confirm('확인', '점검완료 하시겠습니까?', onApprove);
        } else {
            return;
        }
    };

    //상세화면 -> 점검완료
    const onApprove = async () => {
        setIsLoading(true);
        if (approveValidation(item)) {
            let param = DataPackage.create('CHK365', 'SaveApprove');
            param.KeyValues.push(DataPackage.NewKeyValue('PLANT_PRCS_ID', item.PLANT_PRCS_ID));
            param.KeyValues.push(DataPackage.NewKeyValue('PLAN_MASTER_ID', item.PLAN_MASTER_ID));
            param.KeyValues.push(DataPackage.NewKeyValue('RESULT_ID', item.RESULT_ID));
            try {
                await ISOAxios.Execute(param);
            } catch (error) {
                e3.modal.alert(alertType.Error, error.message);
            } finally {
                //저장후 점검목록으로 이동
                e3.modal.alert(alertType.Success, '점검 완료 되었습니다.');
                onClose();
            }
        }
        //await onSearch();
        setIsLoading(false);
    };

    //상세화면 -> 결재상신
    const onRequest = async () => {
        if (!item.APPROVAL_LINE_ID) {
            e3.modal.alert(alertType.Error, '결재선이 지정되지 않았습니다. EHS에서 결재선을 관리해주세요');
            return;
        }

        if (item.FOREMAN_LIST) {
            const foremanList = JSON.parse(item.FOREMAN_LIST);
            refSelectForeman.current.showDialog(
                {
                    PLANT_PRCS_ID: item.PLANT_PRCS_ID,
                    PLAN_MASTER_ID: item.PLAN_MASTER_ID,
                    RESULT_ID: item.RESULT_ID,
                    APPROVAL_LINE_ID: item.APPROVAL_LINE_ID,
                    PLAN_TYPE: item.PLAN_TYPE,
                    FACILITY_ID: item.FACILITY_ID
                },
                foremanList
            );
        } else {
            saveRequest(item, '');
        }
    };

    const saveRequest = async (
        { PLANT_PRCS_ID, PLAN_MASTER_ID, RESULT_ID, APPROVAL_LINE_ID, FACILITY_ID, PLAN_TYPE },
        FOREMAN_EMP_NO
    ) => {
        setIsLoading(true);

        let param = DataPackage.create('CHK365', 'SaveRequest');
        param.KeyValues.push(DataPackage.NewKeyValue('PLANT_PRCS_ID', PLANT_PRCS_ID));
        param.KeyValues.push(DataPackage.NewKeyValue('PLAN_MASTER_ID', PLAN_MASTER_ID));
        param.KeyValues.push(DataPackage.NewKeyValue('RESULT_ID', RESULT_ID));
        param.KeyValues.push(DataPackage.NewKeyValue('APPROVAL_LINE_ID', APPROVAL_LINE_ID));
        param.KeyValues.push(DataPackage.NewKeyValue('FOREMAN_EMP_NO', FOREMAN_EMP_NO));
        param.KeyValues.push(DataPackage.NewKeyValue('PLAN_TYPE', PLAN_TYPE));
        param.KeyValues.push(DataPackage.NewKeyValue('FACILITY_ID', FACILITY_ID));
        try {
            await ISOAxios.Execute(param);
        } catch (error) {
            e3.modal.alert(alertType.Error, error.message);
        } finally {
            //저장후 점검목록으로 이동
            e3.modal.alert(alertType.Success, '결재 상신 되었습니다.');
            onClose();
        }
    };
    return (
        <>
            <React.Fragment>
                <ISODialog
                    isOpen={isOpen}
                    onClose={onClose}
                    isReasize={false}
                    isDraggable={false}
                    dialogId="CheckInput_PopUp"
                    fullScreen
                    fullWidth={true}
                >
                    <ISODialogTitle>
                        <div className="pop_top_checklist">
                            <div className="tit">점검표결과입력</div>
                            <div className="tit_list">
                                <a onClick={onClose}>목록</a>
                            </div>
                        </div>
                        <div className="pop_top_checklist_info">
                            <div className="part">주관부서</div>
                            <div className="cont">{item.DEPT_NAME}</div>
                            <div className="part">점검명</div>
                            <div className="cont">{item.TITLE}</div>
                        </div>

                        <Card>
                            <CardContent>
                                <Grid>
                                    <Card className="CardContentGridItem CardBox check_sheet">
                                        <div className="check_sheet_tit">점검내용</div>
                                        <div className="check_sheet_con">
                                            {' '}
                                            <ISOTextArea
                                                ref={summary}
                                                onReady={onReady}
                                                rowsMax={4}
                                                disabled={
                                                    item.STATUS === 'M_CP_STATUS_REQUEST' ||
                                                    item.STATUS === 'M_CP_STATUS_COMPLETE' ||
                                                    item.STATUS === 'M_CP_STATUS_APPROVE'
                                                        ? true
                                                        : false
                                                }
                                            />
                                        </div>
                                    </Card>
                                </Grid>
                            </CardContent>
                        </Card>
                    </ISODialogTitle>

                    <ISODialogContent>
                        <Container className={'dialog-mobile-full-content-body'}>
                            {(factList && item.PLAN_TYPE === 'M_CP_PLAN_INSPC2') ||
                            item.PLAN_TYPE === 'M_CP_PLAN_INSPC3' ? (
                                factList.map((fact, idx) => (
                                    <SheetForm
                                        key={idx}
                                        ref={(el) => (sheetFormRef.current[idx] = el)}
                                        item={item}
                                        fact={fact}
                                    ></SheetForm>
                                ))
                            ) : (
                                <SheetForm ref={(el) => (sheetFormRef.current[0] = el)} item={item}></SheetForm>
                            )}

                            <Card>
                                <CardContent>
                                    <Grid>
                                        <Card className="CardContentGridItem CardBox check_sheet">
                                            <div className="check_sheet_tit">F/U 필요</div>
                                            <div className="check_sheet_con">
                                                <div className="mt-10 follow_up">
                                                    <ISOCombobox ref={refFuYn} codeId={'FU_YN'} onReady={onFuYnReady} />
                                                </div>
                                            </div>
                                        </Card>
                                    </Grid>

                                    <FilesContainer ref={refFilesContainer} item={item} />
                                </CardContent>
                            </Card>

                            <div className="ml--15 mr--15">
                                <ButtonGroup disableElevation fullWidth variant="contained">
                                    <Button
                                        color={
                                            item.STATUS === 'M_CP_STATUS_REQUEST' ||
                                            item.STATUS === 'M_CP_STATUS_COMPLETE' ||
                                            item.STATUS === 'M_CP_STATUS_APPROVE'
                                                ? 'default'
                                                : 'secondary'
                                        }
                                        disabled={
                                            item.STATUS === 'M_CP_STATUS_REQUEST' ||
                                            item.STATUS === 'M_CP_STATUS_COMPLETE' ||
                                            item.STATUS === 'M_CP_STATUS_APPROVE'
                                                ? true
                                                : false
                                        }
                                        className="focus-button"
                                        type="submit"
                                        onClick={onSaveClick}
                                    >
                                        저장
                                    </Button>
                                    <Button
                                        color={item.STATUS === 'M_CP_STATUS_SAVE' ? 'primary' : 'default'}
                                        disabled={item.STATUS === 'M_CP_STATUS_SAVE' ? false : true}
                                        className="major-button"
                                        type="submit"
                                        onClick={onApproveClick}
                                    >
                                        {item.APPROVAL_YN !== 'N' ? '결재상신' : '점검완료'}
                                    </Button>
                                </ButtonGroup>
                            </div>
                        </Container>
                    </ISODialogContent>
                </ISODialog>
                <SelectForeman ref={refSelectForeman} callbackFn={saveRequest} />
                <Loader isShow={isLoading} />
            </React.Fragment>
        </>
    );
});

export default CheckInput_PopUp;
