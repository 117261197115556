import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container, Typography } from '@material-ui/core';

const NotFound = () => (
    <React.Fragment>
        <Helmet>
            <title>404 - 파일이 존재하지 않습니다.</title>
        </Helmet>
        <Box className="MainLayoutBox">
            <Container maxWidth="md">
                <Typography align="center" color="textPrimary" variant="h1">
                    404: The page you are looking for isn’t here
                </Typography>
                <Typography align="center" color="textPrimary" variant="subtitle2">
                    You either tried some shady route or you came here by mistake. Whichever it is, try using the
                    navigation
                </Typography>
                <Box className="textAlignCenter">
                    <img
                        alt="Under development"
                        src="./static/images/undraw_page_not_found_su7k.svg"
                        className="MainLayoutBoxItem4Image"
                    />
                </Box>
            </Container>
        </Box>
    </React.Fragment>
);

export default NotFound;
