import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { e3, alertType } from 'src/framework/core/utils/utils';
import PropTypes from 'prop-types';
import {
    Box,
    Card,
    CardContent,
    // Divider,
    Grid,
    Typography,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    // TableHead,
    // TablePagination,
    TableRow,
    RadioGroup,
    Radio,
    FormControlLabel
} from '@material-ui/core';
import { ISOCheckBox, ISOInput, ISOTextField, ISORadio } from 'src/framework/components/controls';
import ISOAxios, { DataPackage } from 'src/framework/core/apis/ISOAxios';
import { ViewArraySharp } from '@material-ui/icons';
import SheetItemRadio from './SheetItemRadio';
import SheetItemCheckbox from './SheetItemCheckbox';
// import {
//   AccessTime as AccessTimeIcon,
//   Work as GetAppIcon,
//   Build as BuildIcon,
// } from '@material-ui/icons';

const SheetList = forwardRef((props, ref) => {
    const { item, onClick, fact, renderCnt } = props;
    const [sheetItems, setSheetItems] = useState([]);
    const [value, setValue] = useState('');

    // State SheetItems 중 특정 값 하나만 업데이트 하는 함수
    const updateSheetItems = (planMasterId, indexMasterId, value, setOrUnset = 'set', idx = 0, descIdx = '') => {
        const newSheetItems = sheetItems.map((item, index) => {
            if (
                item.PLAN_MASTER_ID === planMasterId &&
                (item.DESC_IDX ? item.DESC_IDX === descIdx : item.INDEX_MASTER_ID === indexMasterId)
            ) {
                let newValue = '';
                if (item.OBJECT_TYPE !== 'checkbox') {
                    if (item.DEFAULT_VALUE && item.DEFAULT_VALUE !== value) {
                        item.DEFAULT_YN = 'Y';
                    } else {
                        item.DEFAULT_YN = '';
                    }
                    newValue = [{ ITEMS_VALUE: value }];
                    return {
                        ...item,
                        ITEMS_VALUE: newValue
                    };
                }

                // checkbox 인 경우, array로 만들어줌
                if (item.OBJECT_TYPE === 'checkbox' && setOrUnset === 'set') {
                    item.ITEM_DESC[idx].CHECKED = value;
                    //set일때 디폴트값이랑 선택한 값이랑 다르면 불켜짐!
                    if (item.DEFAULT_VALUE && item.DEFAULT_VALUE !== item.ITEM_DESC[idx].CHECKED) {
                        item.DEFAULT_YN = 'Y';
                    } else {
                        item.DEFAULT_YN = '';
                    }
                    return item;
                }
                if (item.OBJECT_TYPE === 'checkbox' && setOrUnset === 'unset') {
                    //unset일때 디폴트값이랑 선택한 값이랑 같으면 불켜짐!
                    if (item.DEFAULT_VALUE && item.DEFAULT_VALUE === item.ITEM_DESC[idx].CHECKED) {
                        item.DEFAULT_YN = 'Y';
                    } else {
                        item.DEFAULT_YN = '';
                    }
                    item.ITEM_DESC[idx].CHECKED = '';
                    return item;
                }
            } else {
                return item;
            }
        });
        setSheetItems(newSheetItems);
    };

    const textOnchange = (e, idx, item) => {
        //sheetItems[idx].ITEMS_VALUE = e.target.value;
        //updateSheetItems(planMasterId, indexMasterId, value, setOrUnset = 'set', idx = 0)
        if (item.OBJECT_TYPE === 'number') {
            let check = /^[0-9]+$/;

            if (!check.test(e.target.value) && e.target.value.length !== 0) {
                e3.modal.alert(alertType.Error, '숫자가 아닌 다른 형식이 입력되었습니다. 숫자만 입력 해주세요.');
            }
        }
        updateSheetItems(item.PLAN_MASTER_ID, item.INDEX_MASTER_ID, e.target.value, '', idx);
    };
    const resLenChk = (item, idx) => {
        let array = [];
        if (item.OBJECT_TYPE === 'text' || item.OBJECT_TYPE === 'number') {
            array.push(
                <ISOTextField
                    key={idx}
                    multiable={4}
                    value={item.ITEMS_VALUE ? item.ITEMS_VALUE[0].ITEMS_VALUE : ''}
                    onChange={(e) => textOnchange(e, idx, item)}
                    disabled={
                        props.item.STATUS === 'M_CP_STATUS_REQUEST' ||
                        props.item.STATUS === 'M_CP_STATUS_COMPLETE' ||
                        props.item.STATUS === 'M_CP_STATUS_APPROVE'
                            ? true
                            : false
                    }
                    placeholder={item.OBJECT_TYPE === 'number' ? '숫자만 입력' : ''}
                ></ISOTextField>
            );
        }
        return array;
    };

    const chkCnt = async () => {
        let cnt = 0;
        for (let i = 0; i < sheetItems.length; i++) {
            if (sheetItems[i].OBJECT_TYPE !== 'checkbox') {
                if (sheetItems[i].ITEMS_VALUE !== null && sheetItems[i].ITEMS_VALUE[0].ITEMS_VALUE.length > 0) {
                    cnt += 1;
                }
            }
            if (sheetItems[i].OBJECT_TYPE === 'checkbox') {
                let checkCnt = 0;
                for (let y = 0; y < sheetItems[i].ITEM_DESC.length; y++) {
                    if (sheetItems[i].ITEM_DESC[y].CHECKED === '') {
                        checkCnt += 1;
                    } else {
                        continue;
                    }
                }
                if (checkCnt !== sheetItems[i].ITEM_DESC.length) {
                    cnt += 1;
                }
            }
        }
        renderCnt(sheetItems[0]?.CNT, cnt);
    };

    useEffect(() => {
        onSearch();
    }, []);

    useEffect(async () => {
        await chkCnt();
    }, [sheetItems]);

    const onSearch = async () => {
        if (item.RESULT_ID) {
            let param = DataPackage.create('CHK365', 'SelectMcpList');
            param.KeyValues.push(
                DataPackage.NewKeyValue(
                    'RESULT_ID',
                    item.PLAN_TYPE === 'M_CP_PLAN_INSPC3' ? fact.RESULT_ID : item.RESULT_ID
                )
            );
            param.KeyValues.push(
                DataPackage.NewKeyValue(
                    'FACILITY_CODE',
                    item.PLAN_TYPE === 'M_CP_PLAN_INSPC3' ? fact.FACILITY_ID : item.FACILITY_CODE
                )
            );

            //체크리스트 점검항목 일 경우
            if (fact) {
                param.KeyValues.push(DataPackage.NewKeyValue('FACILITY_ID', fact.FACILITY_ID));
                param.KeyValues.push(DataPackage.NewKeyValue('PLANT_PRCS_ID', item.PLANT_PRCS_ID));
                param.KeyValues.push(DataPackage.NewKeyValue('PLAN_MASTER_ID', item.PLAN_MASTER_ID));
                param.KeyValues.push(DataPackage.NewKeyValue('TARGET_DEPT', item.TARGET_DEPT));
                param.KeyValues.push(DataPackage.NewKeyValue('PLAN_TYPE', item.PLAN_TYPE));
            }
            try {
                let response = (await ISOAxios.Execute(param)).JsonData;
                let parseRes = JSON.parse(response);
                let result = parseRes.map((item) => ({
                    ...item,
                    ITEM_DESC: JSON.parse(item.ITEM_DESC),
                    ITEMS_VALUE: JSON.parse(item.ITEMS_VALUE)
                }));
                console.log('result:::', result);
                setSheetItems(result);
            } catch (error) {
                e3.modal.alert(alertType.Error, error.message);
            }
        }
    };

    useImperativeHandle(ref, () => ({
        getResult: () => {
            return sheetItems;
        },
        onSearch: () => {
            onSearch();
        }
    }));

    return (
        // <Card className="CardContentGridItem" onClick={onClick}>
        //     <CardContent>
        //         <div>
        sheetItems ? (
            sheetItems.map((item, index) =>
                item.OBJECT_TYPE === 'checkbox' ? (
                    <SheetItemCheckbox
                        key={index}
                        status={props.item.STATUS}
                        item={item}
                        updateSheetItems={updateSheetItems}
                    />
                ) : item.OBJECT_TYPE === 'radio' || item.OBJECT_TYPE === 'combobox' ? (
                    <SheetItemRadio
                        key={index}
                        item={item}
                        status={props.item.STATUS}
                        updateSheetItems={updateSheetItems}
                    />
                ) : (
                    <div key={index} className="check_sheet_con con_line">
                        <div className="check_sheet_problem">{item.MCP_GBN}</div>
                        <div className="check_sheet_ex">{resLenChk(item, index)}</div>
                    </div>
                )
            )
        ) : (
            <Typography color="textPrimary" gutterBottom variant="h3">
                조회된 자료가 존재하지 않습니다.
            </Typography>
        )
        //         </div>
        //     </CardContent>
        // </Card>
    );
});

export default SheetList;
