import React, { PureComponent, useRef } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ISOButton from './ISOButton';
import { e3, divName } from '../../core/utils/utils';

class ISOConfirmDialog extends PureComponent<{}> {
    constructor(props) {
        super(props);

        this.close = this.close.bind(this);
        this.onConfirmClick = this.onConfirmClick.bind(this);
        this.onCloseClick = this.onCloseClick.bind(this);

        this.state = {
            isOpen: true
        };
    }

    componentDidMount() {
        if (this.props.onInit) {
            this.props.onInit();
        }
    }

    /**
     * Dialog Close
     */
    close = () => {
        this.setState({
            isOpen: false
        });
        e3.ui.removeElementReconfirm(divName.conFirmDivName);
    };

    /**
     * 확인 버튼 Event
     * @param e
     */
    onConfirmClick = (e) => {
        if (this.props.onConfirmClick) {
            this.props.onConfirmClick(e);
        }

        this.close();
    };

    /**
     * 취소 버튼 Event
     * @param e
     */
    onCloseClick = (e) => {
        if (this.props.onCloseClick) {
            this.props.onCloseClick(e);
        }

        this.close();
    };

    render() {
        return (
            <div>
                {this.props.flag == true ? (
                    <Dialog
                        open={this.state.isOpen}
                        onClose={this.close}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        className="iso-modal-alert exit-time"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {this.props.title ? this.props.title : this.props.children}
                        </DialogTitle>
                        <DialogContent className="iso-modal-alert-contents">
                            <DialogContentText id="alert-dialog-description" aria-setsize={600}>
                                {this.props.contents ? this.props.contents : this.props.children}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions className="iso-modal-alert-action">
                            <ISOButton color="primary" onClick={this.onConfirmClick}>출문 완료하기</ISOButton>
                            <ISOButton onClick={this.onCloseClick}>출문 취소하기</ISOButton>
                        </DialogActions>
                    </Dialog>
                ) : (
                    <Dialog
                        open={this.state.isOpen}
                        onClose={this.close}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        className="iso-modal-alert"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {this.props.title ? this.props.title : this.props.children}
                        </DialogTitle>
                        <DialogContent className="iso-modal-alert-contents">
                            <DialogContentText id="alert-dialog-description" aria-setsize={600}>
                                {this.props.contents ? this.props.contents : this.props.children}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions className="iso-modal-alert-action">
                            <ISOButton onClick={this.onConfirmClick}>확인</ISOButton>
                            <ISOButton onClick={this.onCloseClick}>닫기</ISOButton>
                        </DialogActions>
                    </Dialog>
                )}
            </div>
        );
    }
}

ISOConfirmDialog.propTypes = {
    title: PropTypes.string.isRequired,
    onInit: PropTypes.func,
    onConfirmClick: PropTypes.func,
    onCloseClick: PropTypes.func,
    flag: PropTypes.bool
};

ISOConfirmDialog.defaultProps = {
    title: '제목',
    onInit: null,
    onConfirmClick: null,
    onCloseClick: null,
    flag: false
};

export default ISOConfirmDialog;
