import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Card,
    CardContent,
    // Divider,
    Grid,
    Typography,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    // TableHead,
    // TablePagination,
    TableRow,
    RadioGroup,
    Checkbox,
    Radio,
    FormControlLabel
} from '@material-ui/core';
import { ISOCheckBox, ISOInput, ISOTextField, ISORadio } from 'src/framework/components/controls';
import ISOAxios, { DataPackage } from 'src/framework/core/apis/ISOAxios';
import { ViewArraySharp } from '@material-ui/icons';
// import {
//   AccessTime as AccessTimeIcon,
//   Work as GetAppIcon,
//   Build as BuildIcon,
// } from '@material-ui/icons';

const SheetItemCheckbox = (props) => {
    const { item, updateSheetItems, status } = props;

    const handleChecked = (e, value, idx) => {
        updateSheetItems(item.PLAN_MASTER_ID, item.INDEX_MASTER_ID, value, e.target.checked ? 'set' : 'unset', idx);
    };

    return (
        <div className="check_sheet_con">
            <div className={item.DEFAULT_YN === 'Y' ? 'check_sheet_problem bad' : 'check_sheet_problem'}>
                {item.MCP_GBN.replace('!$!', '')}
            </div>
            <div className="check_sheet_ex">
                {item?.ITEM_DESC?.map((desc, idx) => (
                    <FormControlLabel
                        key={idx}
                        control={
                            <Checkbox
                                checked={desc.CHECKED === desc.VALUE}
                                disabled={
                                    status === 'M_CP_STATUS_REQUEST' ||
                                    status === 'M_CP_STATUS_COMPLETE' ||
                                    status === 'M_CP_STATUS_APPROVE'
                                        ? true
                                        : false
                                }
                                onChange={(e) => handleChecked(e, desc.VALUE, idx)}
                            />
                        }
                        label={desc.LABEL}
                    />
                ))}
            </div>
        </div>
    );
};

export default SheetItemCheckbox;
