const ITEM_LIST = 'LIST';
const ITEM_ADD = 'ADD';
const ITEM_REMOVE = 'REMOVE';
const ITEM_RESET = 'RESET';
const REDUCER_PAGEROLE = 'REDUCER_PAGEROLE';

/// redux Action (plain action only)
export const list = (data) => ({
  type: ITEM_LIST,
  target: REDUCER_PAGEROLE,
  data,
});
export const add = (data) => ({
  type: ITEM_ADD,
  target: REDUCER_PAGEROLE,
  data,
});
export const remove = (data) => ({
  type: ITEM_REMOVE,
  target: REDUCER_PAGEROLE,
  data,
});
export const reset = () => ({
  type: ITEM_RESET,
  target: REDUCER_PAGEROLE,
});

/// state - state object structure (reducer initialize object )
/// { menuIDKey: [buttonRoles] }
export const initialState = {
  roles: [],
};

function removeByKey(myObj, deleteKey) {
  return Object.keys(myObj)
    .filter((key) => key !== deleteKey)
    .reduce((result, current) => {
      result[current] = myObj[current];
      return result;
    }, {});
}

/// reducer - state object change and return
export default function PageRoleReducer(state = initialState, action) {
  if (action.target !== REDUCER_PAGEROLE) return state;
  const roles = state.roles;
  // console.log('Reducer ===> PageRoleReducer state, action :: ', state, action);
  switch (action.type) {
    case ITEM_LIST:
      return {
        roles: action.data,
      };
    case ITEM_ADD:
      return {
        ...state,
        roles: roles.filter((item) => {
          if (Object.keys(item)[0] === Object.keys(action.data)[0]) return true;
          return false;
        }).length < 1 ? roles.concat(action.data) : roles,
      };
    case ITEM_REMOVE:
      return removeByKey(state, action.data);
    case ITEM_RESET:
      return initialState;
    default:
      return state;
  }
}
