import { useSelector } from 'react-redux';
import ISOAxios, { DataPackage } from '../../framework/core/apis/ISOAxios';
import { e3 } from 'src/framework/core/utils/utils';
import { PartyMode } from '@material-ui/icons';

const ActionEvent = {
    GetUser: () => {
        const tmp = window.sessionStorage.getItem('loginSign');
        const ret = tmp && tmp.length > 0 ? JSON.parse(e3.base64Decode(tmp.toString())) : undefined;
        return ret;
    },
    GePersonalSajin: () => {
        const tmp = window.sessionStorage.getItem('PersonalSajin');
        const ret = tmp && tmp.length > 0 ? e3.base64Decode(tmp.toString()) : './static/images/avatars/nopicture.png';
        return ret;
    }
};

export default ActionEvent;
