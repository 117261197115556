import React from 'react';
import JqxGrid, { jqx } from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid';
import JqxMenu from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxmenu';
import PropTypes from 'prop-types';
import base64 from 'base-64';
import Loader from '../loaderable/Loader';

class ISOGrid extends React.PureComponent {
    grid = React.createRef(JqxGrid);
    contextMenu = React.createRef(JqxMenu);

    seleectedRow;

    constructor(props) {
        super(props);

        this.addRow = this.addRow.bind(this);
        this.deleteRow = this.deleteRow.bind(this);
        this.deleteRowByIndex = this.deleteRowByIndex.bind(this);
        this.setDataBinding = this.setDataBinding.bind(this);
        this.selectrow = this.selectrow.bind(this);
        this.unselectrow = this.unselectrow.bind(this);
        this.getSelectedRow = this.getSelectedRow.bind(this);
        this.getSelectedrows = this.getSelectedrows.bind(this);
        this.clearSelection = this.clearSelection.bind(this);
        this.onRowClick = this.onRowClick.bind(this);
        this.onItemClick = this.onItemClick.bind(this);
        this.onRightClick = this.onRightClick.bind(this);
        this.onRowdoubleclick = this.onRowdoubleclick.bind(this);

        this.getcell = this.getcell.bind(this);
        this.getcellvalue = this.getcellvalue.bind(this);
        this.setcellvalue = this.setcellvalue.bind(this);

        this.getselectedrowindex = this.getselectedrowindex.bind(this);

        this.state = {
            columns: this.props.columns,
            columngroups: this.props.columngroups,
            sortcolumn: this.props.sortcolumn,
            sortdirection: this.props.sortdirection,
            source: {
                localdata: {}
            }
        };
    }

    componentDidMount() {
        document.addEventListener('contextmenu', (event) => event.preventDefault());
    }

    deleteRow = () => {
        let rowid = this.grid.current.getrowid(this.grid.current.getselectedrowindex());
        this.grid.current.deleterow(rowid);
    };

    deleteRowByIndex = (rowIndex) => {
        let rowid = this.grid.current.getrowid(rowIndex);
        this.grid.current.deleterow(rowid);
    };

    /**
     * Grid Data bind..
     * @param datasource
     */
    setDataBinding(datasource) {
        if (!datasource) {
            return [];
        }

        const { columns } = this.state;
        const sortcolumn = this.state.sortcolumn;
        const sortdirection = this.state.sortdirection;

        let datafields = [];
        let convertSource = [];
        columns.forEach((e) => {
            if (e.datafield === 'USE_YN') {
                let useynSource = {
                    datatype: 'array',
                    datafields: [
                        { name: 'label', type: 'string' },
                        { name: 'value', type: 'string' }
                    ],
                    localdata: [
                        { value: 'Y', label: '사용' },
                        { value: 'N', label: '사용안함' }
                    ]
                };
                let dataAdapter = new jqx.dataAdapter(useynSource, { autoBind: true });
                datafields.push({
                    name: 'USEYNNME',
                    value: e.datafield,
                    values: {
                        source: dataAdapter.records,
                        value: 'value',
                        name: 'label'
                    }
                });
                datafields.push({
                    name: e.datafield,
                    type: 'string'
                });
            } else {
                datafields.push({
                    name: e.datafield,
                    type: e.columnType,
                    format: e.cellsformat
                });
            }
        });

        if (typeof datasource === 'string') {
            convertSource = JSON.parse(datasource);
        } else {
            convertSource = datasource;
        }

        convertSource.forEach((e, index) => {
            e.NO = index + 1;
        });

        const source = new jqx.dataAdapter({
            datatype: 'array',
            datafields: datafields,
            localdata: convertSource,
            sortdirection: sortdirection,
            sortcolumn: sortcolumn
        });

        this.setState({
            source: source
        });

        if (this.props.selectionmode === 'checkbox' && this.state.source.records) {
            this.clearSelection();

            this.state.source.records.map((e, i) => {
                if (e.CHK && e.CHK === '1') {
                    this.grid.current.selectrow(e.boundindex);
                }
            });
        }
    }

    getDataSource() {
        return this.state.source.records;
    }

    /**
     * column Setting
     * @param colgroup
     */
    setColumns = (columns) => {
        this.setState({
            columns: columns
        });
    };

    /**
     * column Group Setting
     * @param colgroup
     */
    setColumngroups = (colgroup) => {
        this.setState({
            columngroups: colgroup
        });
    };

    /**
     * Grid Row select
     * @param indexs
     */
    selectrow(indexs) {
        if (this.grid.current.getselectedrowindex() !== indexs) {
            if (this.grid.current.getselectedrowindex() > -1) {
                this.grid.current.unselectrow(this.grid.current.getselectedrowindex());
            }
            this.grid.current.selectrow(indexs);
            this.seleectedRow = this.grid.current.getrows().find((e) => e.boundindex === indexs);
        }
    }

    /**
     * Grid Row unSelect
     * @param index
     */
    unselectrow(index) {
        this.grid.current.unselectrow(index);
    }

    /**
     * 행ID로 행 정보 찾기
     * @param id
     * @returns {*}
     */
    getRowDataById = (id) => this.grid.current.getrowdatabyid(id);

    getRowData = (rowIndex) => this.grid.current.getrowdata(rowIndex);

    /**
     * 선택된 행 반환(단수)
     * @returns {null}
     */
    getSelectedRow() {
        const index = this.grid.current.getselectedrowindex();
        let returnValue = null;
        if (this.seleectedRow) {
            returnValue = this.seleectedRow;
        } else {
            returnValue = this.grid.current.getrows().find((e) => e.boundindex === index);
        }
        return returnValue;
    }

    /**
     * 선택된 행 반환(복수)
     * @returns {null}
     */
    getSelectedrows() {
        const indexs = this.grid.current.getselectedrowindexes();
        const array = [];
        indexs.forEach((e) => {
            array.push(this.grid.current.getrows().find((f) => f.boundindex === e));
        });
        return array;
    }

    /**
     * 행 추가
     */
    addRow = () => {
        let { source, columns } = this.state;
        let rowItem = {};
        columns.forEach((r) => {
            rowItem[r.datafield] = '';
        });
        if (source.records === undefined) {
            let ary = [];
            ary.push(rowItem);
            this.setDataBinding(ary);
        } else {
            this.grid.current.addrow(source.records.length + 1, rowItem);
        }
    };

    /**
     * 선택된 행 초기화
     */
    clearSelection = () => {
        this.grid.current.clearselection();
    };

    dataClear = () => {
        this.setState({ source: {} });
    };

    /**
     * 전체 로우 반환
     * @returns {*}
     */
    getRows = () => this.grid.current.getrows();

    getSortColumn = () => this.grid.current.getsortcolumn();

    setSortColumn = (sortcolumn) => {
        this.setState({
            sortcolumn: sortcolumn
        });
    };

    setSortDirection = (sortdirection) => {
        this.setState({
            sortdirection: sortdirection
        });
    };

    /**
     * 전체 행 선택
     */
    setSelectallRows = () => {
        this.grid.current.selectallrows();
    };

    setcellvalue = (rowBoundIndex, dataField, cellValue) => {
        this.grid.current.setcellvalue(rowBoundIndex, dataField, cellValue);
    };
    getcellvalue = (rowBoundIndex, dataField) => this.grid.current.getcellvalue(rowBoundIndex, dataField);
    getcell = (rowBoundIndex, dataField) => this.grid.current.getcell(rowBoundIndex, dataField);

    getselectedrowindex() {
        const index = this.grid.current.getselectedrowindex();
        return index;
    }

    /**
     * 행 선택 이벤트
     * @param e
     */
    onRowClick = (e) => {
        this.seleectedRow = e.args.row.bounddata;
        if (e.args.rightclick) {
            this.onRightClick(e);
        }
    };

    onItemClick(event, filename = 'csvfile.csv') {
        // 한글 깨짐
        if (this.state) {
            var jsonmodel = {};
            jsonmodel.columns = [];
            jsonmodel.records = [];
            // Header
            this.state?.columns.forEach((col) => {
                if (col.hidden === false) jsonmodel.columns.push(col.text);
            });
            // Records
            this.state?.source?.records.forEach((item) => {
                var row = [];
                this.state?.columns.forEach((col) => {
                    if (col.hidden === false) row.push(item[col.datafield]);
                });
                for (var i = 0; i < row.length; i++) {
                    if (row[i] != null) row[i] = row[i].toString().replaceAll(',', '');
                }
                jsonmodel.records.push(row);
            });

            var flag = ',';
            var csvFiles = jsonmodel.columns.join(flag) + '\r\n';
            jsonmodel.records.forEach((row) => {
                csvFiles += row.join(flag) + '\r\n';
            });

            if (csvFiles) {
                const blob = new Blob(['\uFEFF' + csvFiles], {
                    type: 'text/csv; charset=utf-18'
                });
                if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    window.navigator.msSaveBlob(blob, filename);
                    return;
                }
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.style.display = 'none';
                link.href = url;
                link.setAttribute('download', filename);
                if (typeof link.download === 'undefined') {
                    link.setAttribute('target', '_blank');
                }
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setTimeout(() => {
                    window.URL.revokeObjectURL(url);
                }, 100);
            }
        }
        //this.grid.current.exportdata('csv', 'csv', true, null, false, null, 'euc-kr');
    }

    /**
     * 그리드 오른쪽 클릭
     * @param e
     * @returns {boolean}
     */
    onRightClick(e) {
        const scrollTop = window.scrollY;
        const scrollLeft = window.scrollX;
        this.contextMenu.current.open(
            parseInt(e.args.originalEvent.clientX, 10) + 5 + scrollLeft,
            parseInt(e.args.originalEvent.clientY, 10) + 5 + scrollTop
        );
        return false;
    }

    onRowdoubleclick = (e) => {
        if (this.props.onRowdoubleclick) {
            this.props.onRowdoubleclick(e);
        }
    };

    render() {
        return (
            <>
                <JqxGrid
                    {...this.props}
                    ref={this.grid}
                    source={this.state.source}
                    onRowclick={(e) => {
                        this.onRowClick(e);
                        if (this.props.onRowClick) {
                            this.props.onRowClick(e);
                        }
                    }}
                    onRowdoubleclick={this.onRowdoubleclick}
                    columns={this.state.columns}
                    columngroups={this.state.columngroups}
                />
                <JqxMenu
                    theme={'material'}
                    ref={this.contextMenu}
                    onItemclick={this.onItemClick}
                    width={100}
                    height={58}
                    mode="popup"
                    autoOpenPopup={false}
                >
                    <ul>
                        <li>CSV Export</li>
                    </ul>
                </JqxMenu>
            </>
        );
    }
}

ISOGrid.propTypes = {
    theme: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    columns: PropTypes.array.isRequired,
    columngroups: PropTypes.array,
    pageable: PropTypes.bool,
    autoheight: PropTypes.bool,
    sortable: PropTypes.bool,
    altrows: PropTypes.bool,
    enabletooltips: PropTypes.bool,
    editable: PropTypes.bool,
    selectionmode: PropTypes.string,
    columnsresize: PropTypes.bool,
    onRowClick: PropTypes.func,
    onRowdoubleclick: PropTypes.func,
    toolbarheight: PropTypes.number,
    statusbarheight: PropTypes.number,
    columnsheight: PropTypes.number,
    groupsheaderheight: PropTypes.number
};

ISOGrid.defaultProps = {
    width: '100%',
    height: '100%',
    columns: [],
    columngroups: [],
    pageable: false,
    autoheight: false,
    sortable: true,
    altrows: false,
    enabletooltips: false,
    editable: false,
    selectionmode: 'singlerow',
    columnsresize: true,
    onRowClick: null,
    onRowdoubleclick: null,
    toolbarheight: 24,
    statusbarheight: 24,
    columnsheight: 23,
    groupsheaderheight: 23
};

export default ISOGrid;
