const ITEM_LIST = 'LIST';
const ITEM_RESET = 'RESET';
const REDUCER_MENULIST = 'REDUCER_MENULIST';

/*
흐름 정리
redux reducer 파일 생성 (현재 파일) :: /reducers/object_file_name.js
/reducers/index.js redux reducer 파일 추가 Import
Page Object 구성 파일 추가 :: /pages/page_file_name.js , /pages/page_include directory/page_add_on_file
Page Backend 통신 구성 파일 또는 함수를 Page 파일에 추가한다.
UI Button Action --> Javascript Event --> Backend 통신(Axios) --> Redux Action --> Reducer sate object --> page
*/

/// redux Action (plain action only)
export const menuList = (data) => ({
  type: ITEM_LIST,
  target: REDUCER_MENULIST,
  data,
});
export const menuReset = () => ({
  type: ITEM_RESET,
  target: REDUCER_MENULIST,
});

/// state - state object structure (reducer initialize object )
export const initialState = {
  dataList: [],
};

/// reducer - state object change and return
export default function MenuReducer(state = initialState, action) {
  if (action.target !== REDUCER_MENULIST) return state;
  // const results = state.results;
  // console.log("Reducer ===> MenuReducer state, action :: " , state, action);
  switch (action.type) {
    case ITEM_LIST:
      return {
        ...state,
        dataList: action.data,
      };
    case ITEM_RESET:
      return initialState;
    default:
      return state;
  }
}
