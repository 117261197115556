import React from 'react';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';

class ISOButton extends React.Component {
  constructor(props) {
    super(props);

    this.enable = this.enable.bind(this);
    this.disable = this.disable.bind(this);
    this.setValue = this.setValue.bind(this);
    this.getValue = this.getValue.bind(this);
    this.onClick = this.onClick.bind(this);

    this.state = {
      textLabel: props.textLabel,
      disabled: props.disabled,
      value: props.value,
    };
  }

  enable() {
    if (this.state.disabled) {
      this.setState({
        disabled: false,
      });
    }
  }

  disable() {
    if (!this.state.disabled) {
      this.setState({
        disabled: true,
      });
    }
  }

  setValue(source) {
    if (source) {
      this.setState({
        value: source,
      });
    }
  }

  getValue() {
    return this.state.value;
  }

  setTextLabel(source) {
    this.setState({
      textLabel: source,
    });
  }

  onClick = (e) => {
    if (this.props.onClick !== null) {
      this.props.onClick(e);
    }
  }

  render() {
    const { textLabel, ...others } = this.props;
    return (
      <React.Fragment>
        <Button
          {...others}
          disabled={this.state.disabled}
          value={this.state.value}
          onClick={this.onClick}
        >
          {this.state.textLabel ? this.state.textLabel : this.props.children ? this.props.children : 'button' }
        </Button>
      </React.Fragment>
    );
  }
}

ISOButton.propTypes = {
  textLabel: PropTypes.string,
  color: PropTypes.string,
  component: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  href: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
};

ISOButton.defaultProps = {
  color: 'default',
  component: 'button',
  disabled: false,
  fullWidth: false,
  href: '',
  size: 'medium',
  variant: 'contained',
  value: '',
  onClick: null,
};

export default ISOButton;
