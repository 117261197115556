import React, { forwardRef, useEffect, useState, useImperativeHandle } from 'react';
import { e3, alertType } from 'src/framework/core/utils/utils';
import PropTypes from 'prop-types';
import ISOAxios, { DataPackage } from 'src/framework/core/apis/ISOAxios';
import {
    Box,
    Card,
    CardContent,
    // Divider,
    Grid,
    Typography,
    LinearProgress,
    Table,
    TableBody,
    TableCell,
    // TableHead,
    // TablePagination,
    TableRow
} from '@material-ui/core';
import { ISOCheckBox } from 'src/framework/components/controls';
import SheetList from './SheetList';
// import {
//   AccessTime as AccessTimeIcon,
//   Work as GetAppIcon,
//   Build as BuildIcon,
// } from '@material-ui/icons';

const SheetForm = forwardRef((props, ref) => {
    const { item, onClick, onSave, fact } = props;
    const sheetListRef = React.useRef();
    const [resultItems, setResultItems] = useState([]);
    const [sheetItems, setSheetItems] = useState([]);
    const [totalCnt, setTotalCnt] = useState(0);
    const [ingCnt, setIngCnt] = useState(0);
    const [tgl, setTgl] = useState(
        item.PLAN_TYPE === 'M_CP_PLAN_INSPC2' || item.PLAN_TYPE === 'M_CP_PLAN_INSPC3' ? true : false
    ); //점검항목이 체크리스트&공정점검표 인 경우에는 토글로 form 묶어놓기
    useImperativeHandle(ref, () => ({
        getResult: () => {
            return sheetListRef.current.getResult();
        },
        getOnSearch: () => {
            return sheetListRef.current.onSearch();
        }
    }));
    const renderCnt = (TotalCnt, ingCnt) => {
        setTotalCnt(TotalCnt);
        setIngCnt(ingCnt);
    };
    const formToggle = () => {
        if (tgl === true) {
            setTgl(false);
        } else {
            setTgl(true);
        }
    };
    return (
        <Grid item xs={12} sm={12} lg={12} xl={12} className="mb-10 mt-10">
            <Card className="CardContentGridItem CardBox check_sheet">
                <CardContent>
                    <div className="check_sheet_tit">
                        {fact ? fact.FACILITY_MNG : '점검시트'}{' '}
                        <span>
                            {ingCnt}/{totalCnt}
                        </span>
                        <span className={tgl === true ? 'toggle_off' : 'toggle_on'} onClick={formToggle}></span>
                    </div>
                    <div style={{ display: tgl === true ? 'none' : '' }}>
                        <SheetList ref={sheetListRef} item={item} fact={fact} renderCnt={renderCnt} />
                    </div>
                </CardContent>
            </Card>
        </Grid>

        // <Card className="CardContentGridItem" onClick={onClick}>
        //     <CardContent>
        //         <div>점검시트</div>
        //         <SheetList sheetItem={sheetItems} />;
        //     </CardContent>
        // </Card>
    );
});

export default SheetForm;
