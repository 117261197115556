import React from 'react';
import PropTypes from 'prop-types';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

class ISOTextArea extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value,
            disabled: props.disabled,
            readOnly: props.readOnly
        };
    }
    componentDidMount = async function () {
        if (this.props.onReady) {
            this.props.onReady();
        }
    };
    enable() {
        if (this.state.disabled) {
            this.setState({
                disabled: false
            });
        }
    }

    disable() {
        if (!this.state.disabled) {
            this.setState({
                disabled: true
            });
        }
    }

    setValue(source) {
        this.setState({
            value: source
        });
    }

    getValue() {
        return this.state.value;
    }

    clear() {
        this.setValue('');
    }

    onChange = (e) => {
        this.setState({
            value: e.target.value
        });

        if (this.props.onChange) {
            this.props.onChange(e);
        }
    };

    onClick = (e) => {
        if (this.props.onClick) {
            this.props.onClick(e);
        }
    };

    render() {
        return (
            <TextareaAutosize
                minRows={this.props.rowsMin}
                maxRows={this.props.rowsMax}
                value={this.state.value}
                disabled={this.state.disabled}
                readOnly={this.state.readOnly}
                onClick={this.onClick}
                onChange={this.onChange}
            />
        );
    }
}

ISOTextArea.propsTypes = {
    rowsMin: PropTypes.number,
    rowsMax: PropTypes.number,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    onClick: PropTypes.func,
    onChange: PropTypes.func,
    onReady: PropTypes.func
};

ISOTextArea.defaultProps = {
    rowsMin: 5,
    rowsMax: 5,
    value: '',
    disabled: false,
    readOnly: false,
    onClick: null,
    onChange: null
};

export default ISOTextArea;
