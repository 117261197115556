import React from 'react';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { styled, makeStyles } from '@material-ui/core/styles';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';

const APP_TYPE = process.env.REACT_APP_TYPE;
const APP_PROJECT_NAME = process.env.REACT_APP_PROJECT_NAME;
const APP_COMPANY_NAME = process.env.REACT_APP_COMPANY_NAME;
const APP_COPYRIGHTS_YEAR = process.env.REACT_APP_COPYRIGHTS_YEAR;
const APP_VERSION = process.env.REACT_APP_VERSION;

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up('lg')]: {
            paddingLeft: 256
        }
    }
}));

const DashboardLayout = (props) => {
    const classes = useStyles();
    const [isMobileNavOpen, setMobileNavOpen] = useState(false);

    return (
        <div className="DashboardLayoutRoot">
            <DashboardNavbar onMobileNavOpen={() => setMobileNavOpen(true)} />
            <DashboardSidebar onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />
            <div className={(classes.root, 'DashboardLayoutWrapper')}>
                <div className="DashboardLayoutContainer">
                    <div className="DashboardLayoutContent">
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DashboardLayout;
