/* eslint-disable array-callback-return */
/* eslint-disable func-names */
// import React from 'react';
import ISOAxios, { DataPackage } from '../apis/ISOAxios';
import useSessionStorage from './useSessionStorage';
import { e3 } from 'src/framework/core/utils/utils';

/**
 * 로그인 사용자 정보 확인 및 수정
 * 비밀번호 변경
 */
export default function useUserInfo() {
    const { loadSessionStorage } = useSessionStorage('loginSign');
    const signinUser = loadSessionStorage();

    /**
     * 로그인한 사용자정보를 요청한다.
     * @param {*} Callback (result, error)
     */
    const getProfile = async function (userId) {
        let params = DataPackage.InitSystemDataPackage();
        params.MethodName = 'GetUserProfile';
        params.KeyValues.push(DataPackage.NewKeyValue('USER_ID', userId || signinUser.USER_ID));
        try {
            const response = await ISOAxios.Execute(params);
            return response;
        } catch (e) {
            return e;
        }
    };

    /**
     * 사용자정보를 수정한다.
     * @param {*} Values - Json Object
     * @param {*} Callback (result, error)
     */
    const updateProfile = async function (Values) {
        if (Values === undefined || Values === null || JSON.stringify(Values) === '{}') return null;
        let params = DataPackage.create('SGN100', 'UpdateItem');
        params.KeyValues.push(DataPackage.NewKeyValue('PLT_ID', signinUser.PLT_ID || ''));
        params.KeyValues.push(DataPackage.NewKeyValue('USER_ID', signinUser.USER_ID));
        try {
            Object.keys(Values).map((it) => {
                params.KeyValues.push(DataPackage.NewKeyValue(it, Values[it]));
            });
            const response = await ISOAxios.Execute(params);
            return response;
        } catch (e) {
            return e;
        }
    };

    /**
     * BIZ Procedure PROC_CHANGE_PASSWORD.
     * let resultData = JSON.parse(ret.JsonData)[0];
     * Column - RESULT (resultData.RESULT)
     * 301 - 이전 비밀번호 오류
     * 200 - 정상
     * @param {*} oldPass 이전 비밀번호
     * @param {*} newPass 새로운 비밀번호
     * @param {*} Callback (result, error)
     */
    const changePassword = async function (oldPass, newPass) {
        let params = DataPackage.create('SGN100', 'ChangePassword');
        params.KeyValues.push(DataPackage.NewKeyValue('PLT_ID', signinUser.PLT_ID || ''));
        params.KeyValues.push(DataPackage.NewKeyValue('USER_ID', signinUser.USER_ID));
        params.KeyValues.push(DataPackage.NewKeyValue('OLD_PASS', e3.base64Encode(oldPass)));
        params.KeyValues.push(DataPackage.NewKeyValue('NEW_PASS', e3.base64Encode(newPass)));
        try {
            const response = await ISOAxios.Execute(params);
            return response;
        } catch (e) {
            return e;
        }
    };

    return {
        signinUser,
        getProfile,
        updateProfile,
        changePassword
    };
}
