import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, CardContent, ButtonGroup } from '@material-ui/core';
import { ISODialog, ISODialogTitle, ISODialogContent } from 'src/framework/components/controls/ISODialog';
import { RadioGroup, Radio, FormControlLabel } from '@material-ui/core';

const SelectForeman = React.forwardRef((props, ref) => {
    const saveRequest = props.callbackFn;
    const [item, setItem] = useState({});

    React.useImperativeHandle(ref, () => ({
        showDialog(item, foremanList) {
            setItem(item);
            setForemanList(foremanList);
            setIsOpen(true);
        }
    }));
    const [isOpen, setIsOpen] = useState(false);
    const [foremanList, setForemanList] = useState([]);
    const [selectedForeman, setSelectedForeman] = useState('');

    const onClose = () => {
        setIsOpen(false);
    };
    const handleChange = (e) => {
        setSelectedForeman(e.target.defaultValue);
    };
    const onApproveClick = () => {
        saveRequest(item, selectedForeman);
        onClose();
    };

    return (
        <ISODialog
            isOpen={isOpen}
            onClose={onClose}
            isReasize={false}
            isDraggable={false}
            dialogId="SelectForeman"
            fullWidth={false}
        >
            <ISODialogTitle>
                <Card>
                    <CardContent>
                        <span style={{ fontSize: '1.5em' }}> 포맨 선택</span>
                    </CardContent>
                </Card>
            </ISODialogTitle>
            <ISODialogContent>
                <div style={{ width: '300px' }}>
                    <Card>
                        <CardContent>
                            <RadioGroup className="vertical-radio" name="radio-buttons-group" onChange={handleChange}>
                                {foremanList.map((foreman, i) => {
                                    return (
                                        <FormControlLabel
                                            key={i}
                                            value={foreman.EMP_NO}
                                            control={<Radio />}
                                            label={foreman.EMP_NAME}
                                        />
                                    );
                                })}
                            </RadioGroup>
                        </CardContent>
                    </Card>
                </div>
                <div>
                    <ButtonGroup fullWidth>
                        <Button color="primary" onClick={onApproveClick}>
                            결재상신
                        </Button>
                    </ButtonGroup>
                </div>
            </ISODialogContent>
        </ISODialog>
    );
});

export default SelectForeman;
