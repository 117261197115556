import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import FileUpload from 'src/components/files/FileUpload';
import FileDownload from 'src/components/files/FileDownload';
import { Button, Grid, Card, CardContent } from '@material-ui/core';
import ISOAxios, { DataPackage } from 'src/framework/core/apis/ISOAxios';
import { alertType, e3, validationType } from 'src/framework/core/utils/utils';

function FilesContainer(props, ref) {
    const { item } = props;
    const [removedFileList, setRemovedFileList] = useState([]);
    const [savedFileList, setSavedFileList] = useState([]);
    const [newFileList, setNewFileList] = useState([]);

    const refUploads = useRef([]);

    useEffect(() => {
        if (item && item.RESULT_ID) {
            setFileListFromDB(item.PLANT_PRCS_ID, item.RESULT_ID);
            setRemovedFileList([]);
        } else {
            setSavedFileList([]);
            setRemovedFileList([]);
        }
    }, [item]);

    const setFileListFromDB = async (PLANT_PRCS_ID, RESULT_ID) => {
        let param = DataPackage.create('CHK365', 'SelectAttachFileList');
        param.KeyValues = [
            DataPackage.NewKeyValue('PLANT_PRCS_ID', PLANT_PRCS_ID),
            DataPackage.NewKeyValue('RESULT_ID', RESULT_ID)
        ];
        try {
            const response = await ISOAxios.Execute(param);
            if (response) {
                const jsonData = JSON.parse(response.JsonData);
                setSavedFileList(jsonData);
            } else {
                setSavedFileList([]);
            }
        } catch (error) {
            e3.modal.alert(alertType.Error, error.message);
        }
    };
    const removeSavedFile = (ATCH_MTRL_ID) => {
        const newSavedFileList = savedFileList.filter((element) => element.ATCH_MTRL_ID !== ATCH_MTRL_ID);
        setSavedFileList(newSavedFileList);
        setRemovedFileList([...removedFileList, ATCH_MTRL_ID]);
    };

    const addNewFile = () => {
        if (newFileList.length === 0) {
            setNewFileList([1]);
            return;
        } else {
            setNewFileList([...newFileList, newFileList[newFileList.length - 1] + 1]);
        }
    };
    const removeNewFile = (element) => {
        const filteredList = newFileList.filter((item) => item !== element);
        setNewFileList(filteredList);
    };

    useImperativeHandle(ref, () => ({
        getFilesInfo() {
            return '';
        },
        async saveFiles() {
            return await saveFiles();
        }
    }));

    const saveFiles = async () => {
        try {
            const res1 = await removeSavedFileFromDB();
            if (!res1) return false;

            const res2 = await uploadFiles();
            return res2;
        } catch (ex) {
            return false;
        }
    };

    const removeSavedFileFromDB = async () => {
        console.log('removeSavedFileFromDB', removedFileList);
        if (removedFileList.length > 0) {
            let param = DataPackage.create('CHK365', 'RemoveSavedFiles');
            param.KeyValues = [
                DataPackage.NewKeyValue('PLANT_PRCS_ID', item.PLANT_PRCS_ID),
                DataPackage.NewKeyValue('RESULT_ID', item.RESULT_ID),
                DataPackage.NewKeyValue('ATCH_MTRL_ID_LIST', removedFileList.join(','))
            ];
            try {
                const response = await ISOAxios.Execute(param);
                if (!response) {
                    throw new Error('기존 파일 삭제 실패');
                }
                return true;
            } catch (error) {
                e3.modal.alert(alertType.Error, error.message);
                return false;
            }
        }
        return true;
    };

    const uploadFiles = async () => {
        //newFileList.forEach(async (el) =>
        for (let el of newFileList) {
            if (refUploads.current[el]?.getFile()) {
                const ret = await refUploads.current[el]?.getUploadedFile();
                if (!ret) {
                    e3.modal.alert(alertType.Error, '파일 업로드 실패');
                    return false;
                }
                if (ret) {
                    try {
                        console.log('uploaded', ret);
                        if (ret.ErrorMessage) {
                            e3.modal.alert(alertType.Error, ret.ErrorMessage);
                            return false;
                        }
                        const result = JSON.parse(ret.JsonData);
                        let param = DataPackage.create('CHK365', 'InsertUploadedFile');
                        param.KeyValues = [
                            DataPackage.NewKeyValue('PLANT_PRCS_ID', item.PLANT_PRCS_ID),
                            DataPackage.NewKeyValue('ATCH_MTRL_ID', `${el}${item.RESULT_ID}${el}`),
                            DataPackage.NewKeyValue('REF_TABLE_NAME', 'TB_SM_MCPT_RESULT'),
                            DataPackage.NewKeyValue('REF_TABLE_ID', item.RESULT_ID),
                            DataPackage.NewKeyValue('REF_NAME', result.OriFileName),
                            DataPackage.NewKeyValue('REF_PATH', result.FileName),
                            DataPackage.NewKeyValue('FILE_GUBUN', 'MCP6011'),
                            DataPackage.NewKeyValue('REF_SIZE', result.FileSize)
                        ];

                        const response = await ISOAxios.Execute(param);
                        if (!response) {
                            throw new Error('파일 업로드 후 정보 저장 실패');
                        }
                    } catch (error) {
                        e3.modal.alert(alertType.Error, error.message);
                        return false;
                    }
                }
            }
        }
        return true;
        //);
    };

    const isEditable = () => {
        return !(
            item.STATUS === 'M_CP_STATUS_REQUEST' ||
            item.STATUS === 'M_CP_STATUS_COMPLETE' ||
            item.STATUS === 'M_CP_STATUS_APPROVE'
        );
    };

    return !isEditable() && savedFileList.length === 0 ? (
        <></>
    ) : (
        <>
            <Card className="mt-10 CardBox check_sheet">
                <div className="check_sheet_tit attachment">
                    첨부파일
                    {isEditable() ? (
                        <Button className="file_add_del" onClick={addNewFile}>
                            +
                        </Button>
                    ) : (
                        ''
                    )}
                </div>
                {savedFileList.map((element, index) => {
                    return (
                        <div key={index} className="check_sheet_con file_add_group">
                            <FileDownload
                                fileId={element.ATCH_MTRL_ID}
                                fileName={element.REF_NAME}
                                filePath={element.REF_PATH}
                            />
                            <Button
                                className="file_add_del"
                                onClick={() => removeSavedFile(element.ATCH_MTRL_ID)}
                                disabled={!isEditable()}
                            >
                                -
                            </Button>
                        </div>
                    );
                })}
                {newFileList.map((element) => {
                    return (
                        <div key={element} className="check_sheet_con file_add_group">
                            <FileUpload ref={(el) => (refUploads.current[element] = el)} />
                            <Button className="file_add_del" onClick={() => removeNewFile(element)}>
                                -
                            </Button>
                        </div>
                    );
                })}
            </Card>
        </>
    );
}

export default React.forwardRef(FilesContainer);
