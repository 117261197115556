import React from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';

class ISOInput extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      textLabel: this.props.textLabel,
      disabled: this.props.disabled,
      value: this.props.value,
      inputProps: {
        readOnly: this.props.readOnly,
        hidden: this.props.hidden,
      },
      errormessage: this.props.errormessage,
    };

    this.enable = this.enable.bind(this);
    this.disable = this.disable.bind(this);
    this.setValue = this.setValue.bind(this);
    this.getValue = this.getValue.bind(this);
    this.setLabel = this.setLabel.bind(this);
    this.getLabel = this.getLabel.bind(this);
    this.setReadOnly = this.setReadOnly.bind(this);
    this.clear = this.clear.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
  }

  enable() {
    if (this.state.disabled) {
      this.setState({
        disabled: false,
      });
    }
  }

  disable() {
    if (!this.state.disabled) {
      this.setState({
        disabled: true,
      });
    }
  }

  setValue(source) {
    this.setState({
      value: source || '',
    });
  }

  getValue() {
    return this.state.value;
  }

  setLabel(source) {
    if (source) {
      this.setState({
        textLabel: source,
      });
    }
  }

  getLabel() {
    return this.state.textLabel;
  }

  setReadOnly(source) {
    if (source && typeof (source) === 'boolean') {
      this.setState({
        inputProps: {
          readOnly: source,
        },
      });
    }
  }

  setErrorMessage(message) {
    this.setState({
      errormessage: message,
    });
  }

  clear() {
    this.setState({
      value: '',
      errormessage: null,
    });
  }

  onClick = (e) => {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  }

  onChange = (e) => {
    this.setState({
      value: e.target.value,
    });

    if (this.props.onChange) {
      this.props.onChange(e);
    }
    if (this.props.required && e.target.value === '') {
      this.setState({
        errormessage: '필수 입력입니다.',
      });
    } else {
      this.setState({ errormessage: null });
    }
  }

  onKeyPress = (e) => {
    if (this.props.onKeyPress) {
      this.props.onKeyPress(e);
    }
  }

  control = (other, errormessage) => (
    <TextField
      {...other}
      disabled={this.state.disabled}
      value={this.state.value}
      inputProps={this.state.inputProps}
      onClick={this.onClick}
      onChange={this.onChange}
      onKeyPress={this.onKeyPress}
      { ...(errormessage && { error: true, helperText: errormessage }) }
    />
  )

  render() {
    const { textLabel, ...other } = this.props;
    const { errormessage } = this.state;
    const hiddenStyle = this.props.hidden ? 'hidden' : 'visible';
    return (
      <>
        {this.props.textLabel || this.props.children
          ? (
            <>
              <th style={{ visibility: `${hiddenStyle}` }}>
                <label className={this.props.required ? 'required' : ''}>
                  { this.props.textLabel ? this.props.textLabel : this.props.children ? this.props.children : 'labelInput' }
                </label>
              </th>
              <td colSpan={this.props.colspan ? this.props.colspan : ''} style={{ visibility: `${hiddenStyle}` }}>
                {this.control(other, errormessage)}
              </td>
            </>
          )
          : this.props.colspan
            ? (
              <>
                <td colSpan={this.props.colspan} style={{ visibility: `${hiddenStyle}` }}>
                  {this.control(other, errormessage)}
                </td>
              </>
            )
            : this.control(other, errormessage)}
      </>
    );
  }
}

ISOInput.propTypes = {
  textLabel: PropTypes.string,
  colspan: PropTypes.number,
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  color: PropTypes.string,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  fullWidth: PropTypes.bool,
  multiline: PropTypes.bool,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  type: PropTypes.string,
  hidden: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  errormessage: PropTypes.string,
};

ISOInput.defaultProps = {
  autoComplete: '',
  autoFocus: false,
  color: 'primary',
  disabled: false,
  error: false,
  fullWidth: false,
  multiline: false,
  placeholder: '',
  required: false,
  readOnly: false,
  type: 'text',
  hidden: false,
  value: '',
  onClick: null,
  onChange: null,
  onKeyPress: null,
  errormessage: null,
};

export default ISOInput;
