import React from 'react';
import { Navigate } from 'react-router-dom';

import DashboardLayout from './components/DashboardLayout';
import MainLayout from './components/MainLayout';
import LoginE3 from './pages/LoginE3';
import NotFound from './pages/NotFound';
import CheckList from './pages/CheckList';

const routes = (userInfo) => [
    {
        path: '/app',
        element: userInfo.isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
        children: [
            {
                path: 'checklist',
                element: <CheckList />
            },
            { path: '404', element: <NotFound /> },
            { path: '*', element: <Navigate to="/404" /> }
        ]
    },
    {
        path: '/',
        element: !userInfo.isLoggedIn ? <MainLayout /> : <Navigate to="/app/checklist" />,
        children: [
            { path: '/', element: <Navigate to="/login" /> },
            { path: 'login', element: <LoginE3 /> },
            { path: '404', element: <NotFound /> },
            { path: '*', element: <Navigate to="/404" /> }
        ]
    }
];

export default routes;
