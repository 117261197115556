const ITEM_SET = 'SET';
const ITEM_GET = 'GET';
const REDUCER_RESIZE = 'REDUCER_RESIZE';

/*
흐름 정리
redux reducer 파일 생성 (현재 파일) :: /reducers/object_file_name.js
/reducers/index.js redux reducer 파일 추가 Import
Page Object 구성 파일 추가 :: /pages/page_file_name.js , /pages/page_include directory/page_add_on_file
Page Backend 통신 구성 파일 또는 함수를 Page 파일에 추가한다.
UI Button Action --> Javascript Event --> Backend 통신(Axios) --> Redux Action --> Reducer sate object --> page
*/

/// redux Action (plain action only)
export const setSize = (data) => ({
  type: ITEM_SET,
  target: REDUCER_RESIZE,
  data,
});
export const getSize = () => ({
  type: ITEM_GET,
  target: REDUCER_RESIZE,
});

/// state - state object structure (reducer initialize object )
export const initialState = {
  divSize: {},
};

/// reducer - state object change and return
export default function ResizeReducer(state = initialState, action) {
  if (action.target !== REDUCER_RESIZE) return state;
  // const results = state.results;
  // console.log("Reducer ===> ResizeReducer state, action :: " , state, action);
  switch (action.type) {
    case ITEM_SET:
      return {
        ...state,
        sizeStyle: action.data,
      };
    case ITEM_GET:
      return initialState;
    default:
      return state;
  }
}
