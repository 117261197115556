import React from 'react';

const PAGE_LOADING_IMAGE = `./static/images/wms_loading.gif`;

const Loader = ({ isShow, message, ...rest }) => {
    return (
        <>
            {isShow && (
                <div className="contentWrap overlay loading-wrap" {...rest} style={{ zIndex: '9999' }}>
                    <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                        <h2 className="loading-title-message">{message || ''}</h2>
                        <img src={PAGE_LOADING_IMAGE} alt="loading" />
                        <h2 className="loading-wait-message" style={{ textAlign: 'center' }}>
                            잠시만 기다려 주세요!
                        </h2>
                    </div>
                </div>
            )}
        </>
    );
};

export default Loader;
