import React from 'react';
import { useSelector } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from './components/GlobalStyles';
// import 'react-perfect-scrollbar/dist/css/styles.css';
// import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import theme from './theme';
import routes from './routes';

const App = () => {
    const userInfo = useSelector((state) => state.user);
    const routing = useRoutes(routes(userInfo));

    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            {routing}
        </ThemeProvider>
    );
};

export default App;
