import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { TextField, Button, InputAdornment, SvgIcon } from '@material-ui/core';
import { MoreHorizontal, Search as SearchIcon, TrendingUp } from 'react-feather';
import imageCompression from 'browser-image-compression';
// import { alertType, e3 } from 'src/framework/core/utils/utils';
//import ISOAxios, { DataPackage } from 'src/framework/core/apis/ISOAxios';

const API_URL = process.env.REACT_APP_PROXY.replace('mobileservice', 'FileTransferService');

const FileUpload = React.forwardRef((props, ref) => {
    const [properties, setProperties] = React.useState({
        selectedFile: '',
        displaySize: '',
        base64File: ''
    });
    const inputRef = React.useRef(null);

    const fileSize = (size) => {
        if (size === 0) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        const i = Math.floor(Math.log(size) / Math.log(k));
        return `${parseFloat((size / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
    };

    const handleFileInput = async (e) => {
        console.log('ee:::', e.target.files[0].type);
        let type = e.target.files[0].type;
        if (e.target.files && e.target.files.length > 0) {
            if (type.indexOf('image') !== -1) {
                let res = await handleFileOnChange(e);
                let nBytes = res.size;
                toBase64(res, function (result) {
                    setProperties({
                        selectedFile: res,
                        displaySize: fileSize(nBytes),
                        base64File: result
                    });
                });
                if (props.autoUpload) handlePost(res); // 자동으로 업로드 한다.
            } else {
                let nBytes = e.target.files[0].size;
                toBase64(e.target.files[0], function (result) {
                    setProperties({
                        selectedFile: e.target.files[0],
                        displaySize: fileSize(nBytes),
                        base64File: result
                    });
                });
                if (props.autoUpload) handlePost(e.target.files[0]); // 자동으로 업로드 한다.
            }
        }
    };

    //이미지 파일 리사이즈
    const handleFileOnChange = async (e) => {
        let file = e.target.files[0]; // 입력받은 file객체

        // 이미지 resize 옵션 설정 (최대 width을 2400px로 지정)
        const options = {
            maxSizeMB: 2,
            maxWidthOrHeight: 2400
        };

        try {
            const compressedFile = await imageCompression(file, options);
            console.log('compressedFile:::', compressedFile);

            return blobToFile(compressedFile, compressedFile.name);
        } catch (error) {
            console.log(error);
        }
    };

    function blobToFile(theBlob, fileName) {
        return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type });
    }

    const toBase64 = (file, callback) => {
        var reader = new FileReader();
        reader.readAsDataURL(file); // Image
        reader.onload = function () {
            // let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
            // if ((encoded.length % 4) > 0) {
            //   encoded += '='.repeat(4 - (encoded.length % 4));
            // }
            // resolve(encoded);
            callback(reader.result);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    };

    const handlePost = async (file) => {
        const req = new FormData();
        if (file) {
            req.append('fileStream', file);
            const config = {
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                }
            };
            try {
                const result = await axios.post(`${API_URL}/Upload`, req, config);
                return JSON.parse(result.data);
            } catch (e) {
                console.log('Error', e);
                return null;
            }
        }
        return;
    };

    const handleInputClick = () => {
        inputRef.current.click();
    };

    // Ref Event 상위로 전달 설정
    React.useImperativeHandle(ref, () => ({
        getFile() {
            return properties.selectedFile;
        },
        getBase64File() {
            return properties.base64File;
        },
        async getUploadedFile() {
            var ret = await handlePost(properties.selectedFile);
            return ret;
        },
        Reset() {
            setProperties({
                selectedFile: '',
                displaySize: '',
                base64File: ''
            });
        }
    }));

    return (
        <div className="e3-file-upload-control MuiInputBase-fullWidth">
            {/* <input ref={inputRef} type="file" accept="image/*" name="e3-control-file" onChange={(e) => handleFileInput(e)} style={{display: 'none'}} /> */}
            <input
                ref={inputRef}
                type="file"
                name="e3-control-file"
                onChange={(e) => handleFileInput(e)}
                style={{ display: 'none' }}
                multiple={false}
            />
            <TextField
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <div className="file_add_route">파일첨부</div>
                        </InputAdornment>
                    )
                }}
                variant="outlined"
                size="small"
                disabled={false}
                value={properties.selectedFile ? `${properties.selectedFile.name} (${properties.displaySize})` : ''}
                className="ISOTextField"
                placeholder="파일을 선택하세요!"
                onClick={handleInputClick}
            />
            {props.useDebugButton && (
                <div className="btn_wrap inline-block vertical_top">
                    <Button type="button" onClick={handlePost}>
                        파일 업로드
                    </Button>
                </div>
            )}
        </div>
    );
});

FileUpload.propTypes = {
    useDebugButton: PropTypes.bool,
    autoUpload: PropTypes.bool
};
FileUpload.defaultProps = {
    useDebugButton: false,
    autoUpload: false
};

export default FileUpload;
