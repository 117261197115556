import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Toolbar } from '@material-ui/core';
import Logo from './Logo';

const APP_TYPE = process.env.REACT_APP_TYPE;

const MainNavbar = (props) => (
    <AppBar elevation={0} {...props} className="DashboardHeader">
        <Toolbar className="MainLayoutToolbar">
            <RouterLink to="/" className="DashboardNavbarLogo">
                <Logo />
                <span>
                    <img className="logo" alt="365 캘린더" src="./static/images/logo_365calender.svg" />
                </span>
            </RouterLink>
        </Toolbar>
    </AppBar>
);

export default MainNavbar;
