const MESSAGE_SHOW = 'MESSAGE_SHOW';
const MESSAGE_HIDE = 'MESSAGE_HIDE';
const REDUCER_MESSAGE = 'REDUCER_MESSAGE';

/*
흐름 정리
redux reducer 파일 생성 (현재 파일) :: /reducers/object_file_name.js
/reducers/index.js redux reducer 파일 추가 Import
Page Object 구성 파일 추가 :: /pages/page_file_name.js , /pages/page_include directory/page_add_on_file
Page Backend 통신 구성 파일 또는 함수를 Page 파일에 추가한다.
UI Button Action --> Javascript Event --> Backend 통신(Axios) --> Redux Action --> Reducer sate object --> page
*/

/// redux Action (plain action only)
export const show = (data) => ({
  type: MESSAGE_SHOW,
  target: REDUCER_MESSAGE,
  data,
});
export const hide = () => ({
  type: MESSAGE_HIDE,
  target: REDUCER_MESSAGE,
});

/// state - state object structure (reducer initialize object )
export const initialState = {
  title: '',
  message: '',
  isShow: false,
  width: 600,
  height: 400,
  buttonAlign: 'center',
  type: 'Normal',
};

/// reducer - state object change and return
export default function MessageReducer(state = initialState, action) {
  if (action.target !== REDUCER_MESSAGE) return state;
  // const results = state.results;
  // console.log("Reducer ===> MessageReducer state, action :: " , state, action);
  switch (action.type) {
    case MESSAGE_SHOW:
      return {
        ...state,
        title: action.data.title,
        message: action.data.message,
        isShow: true,
        width: action.data.width || 600,
        height: action.data.height || 400,
        buttonAlign: action.data.buttonAlign || 'center',
        type: action.data.type || 'Normal',
      };
    case MESSAGE_HIDE:
      return initialState;
    default:
      return state;
  }
}
