import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import ISOAxios, { DataPackage } from '../../core/apis/ISOAxios';

class ISOCombobox extends React.PureComponent {
    defaultSelected;

    constructor(props) {
        super(props);

        this.enable = this.enable.bind(this);
        this.disable = this.disable.bind(this);
        this.clear = this.clear.bind(this);
        this.setValue = this.setValue.bind(this);
        this.getValue = this.getValue.bind(this);
        this.setDataBinding = this.setDataBinding.bind(this);
        this.setSelectedIndex = this.setSelectedIndex.bind(this);
        this.onChange = this.onChange.bind(this);

        this.defaultSelected = {};
        this.defaultSelected[this.props.displayMember] = props.placeholder ? props.placeholder : '';
        this.defaultSelected[this.props.valueMember] = '';

        this.state = {
            textLabel: props.textLabel,
            selectedValue: this.defaultSelected,
            source: [],
            multiable: props.multiable
        };
    }

    addBlank(jsonResult) {
        const blankData = { VALUE: '', LABEL: '' };
        jsonResult.unshift(blankData);
        return jsonResult;
    }

    addAll(jsonResult) {
        const allData = { VALUE: '', LABEL: '전체' };
        jsonResult.unshift(allData);
        return jsonResult;
    }

    async componentWillMount() {
        if (this.props.codeId) {
            let params = DataPackage.create('SYS100', 'getCodes');
            params.KeyValues.push(DataPackage.NewKeyValue('codeId', this.props.codeId));
            if (this.props.plantId) {
                params.KeyValues.push(DataPackage.NewKeyValue('plantId', this.props.plantId));
            }
            params.KeyValues.push(DataPackage.NewKeyValue('defaultValue', this.props.defaultValue));
            let result = (await ISOAxios.Execute(params)).JsonData;
            if (this.props.filter) {
                result = JSON.parse(result).filter((item) => item.VALUE === this.props.filter);
            }
            this.setDataBinding(result);
        }
        if (this.props.onReady) {
            this.props.onReady();
        }
    }

    enable() {
        if (this.state.disabled) {
            this.setState({
                disabled: false
            });
        }
    }

    disable() {
        if (!this.state.disabled) {
            this.setState({
                disabled: true
            });
        }
    }

    clear() {
        this.setState({
            selectedValue: this.defaultSelected
        });
    }

    getValue() {
        let value = null;
        if (this.state.selectedValue) {
            if (this.state.multiable && Array(this.state.selectedValue)[0].length !== undefined) {
                value = this.state.selectedValue.map((e) => e.value).join(',');
            } else {
                value = this.state.selectedValue.value;
                if (!value) {
                    value = this.defaultSelected[this.props.valueMember];
                }
            }
        }
        return value;
    }

    async setValue(value) {
        const { source } = this.state;

        if (source.length === 0) return false;

        let array = [];
        if (typeof source === 'array') {
        } else {
            array = source.find((e) => e.value === value);
        }

        await this.setState({
            selectedValue: array
        });
    }

    setSelectedIndex(value) {
        if (value) {
            const { source } = this.state;

            if (source) {
                const array = source.find((e, i) => {
                    if (i === value) {
                        return e;
                    }
                });
                this.setState({
                    selectedValue: array
                });
            }
        }
    }

    getSelectedIndex = () => {
        const index = this.state.source.findIndex((e) => {
            if (typeof this.state.selectedValue === 'array') {
                return null;
            }
            if (e.value === this.state.selectedValue.value) {
                return e;
            }
        });
        return index;
    };
    async setDataBinding(source) {
        if (source) {
            let selected;
            let bindingSource = typeof source === 'string' ? JSON.parse(source) : source;
            if (this.props.isAddAll) {
                bindingSource = this.addAll(bindingSource);
            }
            if (this.props.isAddBlank) {
                bindingSource = this.addBlank(bindingSource);
            }

            if (this.props.placeholder && this.props.defaultValue) {
                bindingSource = [{ label: this.props.placeholder, value: '' }, ...bindingSource];
            }

            let convertSource = bindingSource.map((e) => ({
                label: e[this.props.displayMember],
                value: e[this.props.valueMember]
            }));

            // default로 선택 했을 때의 처리
            if (this.state.source && this.props.placeholder) {
                selected = this.getElementByIndex(convertSource, 0);
            } else if (this.props.defaultIndex > -1) {
                selected = this.getElementByIndex(convertSource, this.props.defaultIndex);
            } else if (this.props.defaultIndices && this.props.defaultIndices.length > 0) {
                selected = this.props.defaultIndices.map((i) => this.getElementByIndex(convertSource, i));
            }

            // 이벤트 강제 발생
            if (selected) {
                this.onChange(selected);
            }

            await this.setState({
                source: convertSource
            });
        }
    }

    getElementByIndex = (source, index) => {
        const element = source.find((e, i) => {
            if (i === index) {
                return e;
            }
        });

        return element;
    };

    async onChange(e) {
        this.setState({
            selectedValue: e == null ? this.defaultSelected : e
        });

        if (this.props.onChange) {
            this.props.onChange(e);
        }
    }

    control = (props) => (
        <Select
            {...props}
            options={this.state.source}
            value={this.state.selectedValue}
            isDisabled={this.state.disabled}
            isClearable={this.props.clearable}
            isSearchable={this.props.searchable}
            isMulti={this.props.multiable}
            onChange={this.onChange}
            className="iso-e3-select"
            classNamePrefix="select"
            styles={{ menu: (provided) => ({ ...provided, zIndex: 991 }) }}
            MenuProps={{
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                },
                transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                },
                getContentAnchorEl: null
            }}
            menuPlacement={this.props.menuPlacement}
        />
    );

    render() {
        return (
            <>
                {this.props.textLabel || this.props.children ? (
                    <>
                        <th>
                            <label className={this.props.required ? 'required' : ''}>
                                {this.state.textLabel ? this.state.textLabel : ''}
                            </label>
                        </th>
                        <td colSpan={this.props.colspan ? this.props.colspan : ''}>{this.control(this.props)}</td>
                    </>
                ) : this.props.colspan ? (
                    <>
                        <td colSpan={this.props.colspan}>{this.control(this.props)}</td>
                    </>
                ) : (
                    this.control(this.props)
                )}
            </>
        );
    }
}

ISOCombobox.propTypes = {
    colspan: PropTypes.number,
    required: PropTypes.bool,
    placeholder: PropTypes.string,
    codeId: PropTypes.string,
    defaultValue: PropTypes.bool,
    textLabel: PropTypes.string,
    clearable: PropTypes.bool,
    searchable: PropTypes.bool,
    disabled: PropTypes.bool,
    multiable: PropTypes.bool,
    displayMember: PropTypes.string,
    valueMember: PropTypes.string,
    onChange: PropTypes.func,
    isAddAll: PropTypes.bool,
    isAddBlank: PropTypes.bool,
    defaultIndex: PropTypes.number,
    defaultIndices: PropTypes.array,
    menuPlacement: PropTypes.oneOf(['auto', 'top']),
    onReady: PropTypes.func
};

ISOCombobox.defaultProps = {
    menuPlacement: 'auto',
    defaultValue: false,
    clearable: false,
    searchable: false,
    disabled: false,
    multiable: false,
    displayMember: 'LABEL',
    valueMember: 'VALUE',
    onChange: null,
    isAddAll: false,
    isAddBlank: false
};

export default ISOCombobox;
