import React from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import base64 from 'base-64';

function FileDownload(props) {
    const API_URL = process.env.REACT_APP_PROXY.replace('mobileservice', 'FileTransferService');
    const { fileId, fileName, filePath } = props;

    const handleGet = async () => {
        if (fileName && filePath) {
            const url = `${API_URL}/Download/${fileId}`;
            window.open(url, '_blank');
        }
    };

    return (
        <div className="e3-file-upload-control MuiInputBase-fullWidth">
            <TextField
                fullWidth
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <div className="file_add_route" onClick={handleGet}>
                                다운로드
                            </div>
                        </InputAdornment>
                    )
                }}
                variant="outlined"
                size="small"
                disabled={true}
                value={fileName ?? ''}
                className="ISOTextField"
                placeholder=""
            />
        </div>
    );
}

export default FileDownload;
