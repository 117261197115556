// const LOGIN = 'LOGIN';
const REDUCER_LOGIN = 'REDUCER_LOGIN';
const LOGOUT = 'LOGOUT';
const RESET_STORE = 'RESET_STORE';
const LOGIN_REQUEST = 'LOGIN_REQUEST';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_FAILURE = 'LOGIN_FAILURE';
const ALLOW_PRIVACY = 'ALLOW_PRIVACY';
const ALLOW_STEP1 = 'ALLOW_STEP1';
const ALLOW_STEP2 = 'ALLOW_STEP2';
const ALLOW_STEP3 = 'ALLOW_STEP3';
const ALLOW_STEP4 = 'ALLOW_STEP4';
const LOGIN_SUCCESS4SAFE = 'LOGIN_SUCCESS4SAFE';

export const login = (data) => {
    // console.log("action login :: ", data);
    if (data.RESULT === 200 || data.RESULT === '200') {
        return {
            type: LOGIN_SUCCESS,
            target: REDUCER_LOGIN,
            result: data
        };
    }
    return {
        type: LOGIN_FAILURE,
        target: REDUCER_LOGIN,
        result: data
    };
};
export const logout = () => ({
    type: LOGOUT,
    target: REDUCER_LOGIN
});

export const clearStore = () => ({
    type: RESET_STORE,
    target: ''
});

export const loginWithOher = (data) => {
    if (data.RESULT === 200) {
        return {
            type: LOGIN_SUCCESS4SAFE,
            target: REDUCER_LOGIN,
            result: data
        };
    }
    return {
        type: LOGIN_FAILURE,
        target: REDUCER_LOGIN,
        result: data
    };
};
export const privacyAllow = () => ({
    type: ALLOW_PRIVACY,
    target: REDUCER_LOGIN
});
export const step1Allow = () => ({
    type: ALLOW_STEP1,
    target: REDUCER_LOGIN
});
export const step2Allow = () => ({
    type: ALLOW_STEP2,
    target: REDUCER_LOGIN
});
export const step3Allow = () => ({
    type: ALLOW_STEP3,
    target: REDUCER_LOGIN
});
export const step4Allow = () => ({
    type: ALLOW_STEP4,
    target: REDUCER_LOGIN
});

export const defaultState = {
    isLoggedIn: false,
    fetchingUpdate: false,
    privacyAllow: false,
    step1Allow: false,
    step2Allow: false,
    step3Allow: false,
    step4Allow: false,
    user: {}
};

export default function loginReducer(state = defaultState, action) {
    if (action.target !== REDUCER_LOGIN) {
        return state;
    }

    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                fetchingUpdate: true
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                fetchingUpdate: false,
                isLoggedIn: true,
                user: action.result
            };
        case LOGIN_FAILURE:
            return {
                ...state,
                fetchingUpdate: false
            };
        case ALLOW_PRIVACY:
            return {
                ...state,
                privacyAllow: true
            };
        case ALLOW_STEP1:
            return {
                ...state,
                step1Allow: true
            };
        case ALLOW_STEP2:
            return {
                ...state,
                step2Allow: true
            };
        case ALLOW_STEP3:
            return {
                ...state,
                step3Allow: true
            };
        case ALLOW_STEP4:
            return {
                ...state,
                step4Allow: true
            };
        case LOGIN_SUCCESS4SAFE:
            return {
                ...state,
                fetchingUpdate: false,
                isLoggedIn: true,
                privacyAllow:
                    action.result?.PRIVACY_CONSENT_DT && action.result?.PRIVACY_CONSENT_DT !== 'null' ? true : false,
                step1Allow: action.result?.SAJIN === 'Y' ? true : false,
                step2Allow: action.result?.SAFE_EDU_CMPLT_YN === 'Y' ? true : false,
                step3Allow: action.result?.PASS_YN === 'Y' ? true : false,
                step4Allow: action.result?.ACCESS_POSSIBLE_YN === 'Y' ? true : false,
                user: action.result
            };
        case LOGOUT:
            return defaultState;
        default:
            return state;
    }
}
